/**
 * Copyright Highway9 Networks Inc.
 */
import { ZoneGroup, Zone } from "~/types/zone";
import APIService from "./APIServices";

const zoneAPI = new APIService<Zone>("zones");

const zoneGroup = new APIService<ZoneGroup>('zoneGroups')

export const VMZService = {
  getVMZData: zoneAPI.getAll,
  getMetrics: zoneAPI.getMetrics,
  addVMZ: zoneAPI.create,
  updateVMZ: zoneAPI.update,
  deleteVMZ: zoneAPI.delete
};

export const VMZGroupService = {
  getVMZGroupData : zoneGroup.getAll,
  addVMZGroup : zoneGroup.create,
  updateVMZGroup : zoneGroup.update,
  deleteVMZGroup : zoneGroup.delete,
};
