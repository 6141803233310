/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { mobilityService } from "../../services/mobility-service";
import { MobilityProfile } from "../../types/mobilityProfile";

type initState = {
  open: boolean;
  current: MobilityProfile | null;
  data: MobilityProfile[];
};

const initialState: initState = {
  open: false,
  current: null,
  data: [],
};

export const fetchMobilityProfiles = createAsyncThunk("mobilityProfile/fetchMobilityProfiles", async () => {
  const data = await mobilityService.getMobilityProfiles();
  return data;
});

const mobilityProfileSlice = createSlice({
  name: "mobilityProfile",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setValues: (state, action: PayloadAction<MobilityProfile>) => {
      state.current = action.payload;
    },
    updateData: (state, action: PayloadAction<MobilityProfile>) => {
      const index = state.data.findIndex((item) => item.id === action.payload.id);
      state.data[index] = action.payload;
    },
    delete: (state, action: PayloadAction<string>) => {
      const index = state.data.findIndex((item) => item.id === action.payload);
      state.data = state.data.filter((_, i) => i !== index);
    },
    setData: (state, action: PayloadAction<MobilityProfile[]>) => {
      state.data = action.payload;

      const current = action.payload.find((item) => item.id === state.current?.id);
      if (current) {
        state.current = current;
      }
    },
    addData: (state, action: PayloadAction<MobilityProfile>) => {
      state.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMobilityProfiles.fulfilled, (state, action) => {
      state.data = action.payload;

      // Sync current with latest data
      if (state.current && !state.open) {
        const current = action.payload.find((item) => item.id === state.current?.id);
        if (current) {
          state.current = current;
        }
      }
    });
  },
});

export const mobilityProfileActions = mobilityProfileSlice.actions;
export default mobilityProfileSlice.reducer;
export const mobilityProfileState = (state: RootState) => state.mobilityProfile.current;
export const mobilityProfileData = (state: RootState) => state.mobilityProfile.data;
export const mobilityProfileOpen = (state: RootState) => state.mobilityProfile.open;
