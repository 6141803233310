/**
 * Copyright Highway9 Networks Inc.
 */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { operatorService } from "../../services/operator-service";
import { OperatorQuery } from "../../types/OperatorQuery";

type initState = {
  open: boolean;
  current: OperatorQuery | null;
  allOperators: OperatorQuery[];
  data: OperatorQuery[];
  error?: string | null;
};

export const initialState: initState = {
  open: false,
  current: null,
  allOperators: [],
  data: [],
};

export const fetchAllOperators = createAsyncThunk("operator/fetchAllOperators", async () => {
  try {
    const response = await operatorService.getAllOperators();
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchOperatorsByEdge = createAsyncThunk(
  "operator/fetchOperatorsByEdge",
  async (edgeId: string | string[]) => {
    try {
      // check if edgeId is an array
      if (Array.isArray(edgeId)) {
        // Call Promise.all to get all the operators
        const operators = await Promise.all(
          edgeId.map(async (id) => {
            const response = await operatorService.getOperatorsByEdge(id);
            return response;
          })
        );
        // flatten the array of arrays
        const flattened = operators.flat();
        return flattened;
      } else {
        const response = await operatorService.getOperatorsByEdge(edgeId);
        return response;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const operatorSlice = createSlice({
  name: "operator",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setValues: (state, action: PayloadAction<OperatorQuery>) => {
      state.current = action.payload;
    },
    setData: (state, action: PayloadAction<OperatorQuery[]>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOperators.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchAllOperators.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchOperatorsByEdge.fulfilled, (state, action) => {
        state.allOperators = action.payload;
      })
      .addCase(fetchOperatorsByEdge.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export const operatorActions = operatorSlice.actions;
export default operatorSlice.reducer;

export const operatorOpen = (state: RootState) => state.operator.open;
export const operatorState = (state: RootState) => state.operator.current;
export const operatorEdgeAll = (state: RootState) => state.operator.allOperators;
export const operatorData = (state: RootState) => state.operator.data;
