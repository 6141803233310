import { jsx as p } from "react/jsx-runtime";
import { Button as s } from "@mui/material";
function y(o) {
  const {
    btype: n = "primary",
    width: t,
    height: e,
    sx: r,
    children: a,
    ...d
  } = o;
  return /* @__PURE__ */ p(
    s,
    {
      sx: [{ width: t, height: e }, i[n], ...Array.isArray(r) ? r : [r]],
      ...d,
      children: a
    }
  );
}
const i = {
  primary: (o) => ({
    borderColor: `${o.palette.grey[200]}`,
    borderRadius: 2,
    height: o.spacing(10),
    padding: o.spacing(0, 2.5),
    textTransform: "none",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none"
    }
  }),
  secondary: (o) => ({
    border: `1px solid ${o.palette.grey[200]}`,
    backgroundColor: o.palette.grey[50],
    color: o.palette.grey[600],
    boxShadow: "none",
    "&:hover": {
      backgroundColor: o.palette.grey[200],
      boxShadow: "none"
    }
  })
};
export {
  y as Button
};
