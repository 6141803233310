/**
 * Copyright Highway9 Networks Inc.
 */
import moment from "moment";
import { realFetch } from "./fetchConfig";

let subscribersToShow = 0;

// PLEASE NOTE: This is a fake backend for demo purposes only. It is not intended to be used in production.
// It is not secure and does not implement any authentication or authorization.
// Put testing data to fakeData.ts file
export function getFakeData(url, opts, resolve, reject) {
  // const isLoggedIn = true //Make this uncomment if you want to use both while developing in local fake backend and proxy for API's
  const isLoggedIn = opts?.headers ? opts.headers["Authorization"] === "Bearer fake-jwt-token" : false;

  // wrap in timeout to simulate server api call
  setTimeout(async () => {
    const fake = await import("./fakeData");
    const {
      radioGroups,
      operators,
      edges,
      sites,
      MobilityProfiles,
      auditLogs,
      radios,
      TechBundleOperationData,
      PacketCapture,
      subscriberMetrics,
      radioMetrics,
      template,
      sasLogs,
      dnn,
      carrierStatus,
      operation,
      edgeMetrics,
      monitoringSystem,
      alarms,
      logs,
      events,
      aaaServerProfiles,
      qci,
      apiToken,
      notes,
      tenants,
      QoSData,
      accessConfigs,
      VMZData,
      connectedEdgeDevices,
      connectedRadios,
      dashboardMetrics,
      deviceGroup,
      diagnosticsRan,
      diagnosticsUe,
      supportChannel,
      edgePool,
      errors,
      exportOperation,
      grafana,
      h9Apn,
      h9Edges,
      harwareInventory,
      networks,
      orders,
      policies,
      radioStats,
      serviceControllers,
      supportChannelStatus,
      systemSettings,
      userInfo,
      subscribers0,
      subscribers1,
      users,
      FlowRecords,
      cacert,
      operatorRadios
    } = fake;

    // authenticate - public
    if (url.endsWith("/api/v1/orch/sessions/login") && opts.method === "POST") {
      const params = JSON.parse(opts.body);
      const user = users.find((x) => x.email === params.email && x.password === params.password);
      if (!user)
        return reject({
          errors: [{ message: "Username or password is incorrect" }],
          warnings: null,
        });
      //return reject(errors)
      return ok({
        id: user.id,
        email: user.email,
        token: "fake-jwt-token",
        userName: user.userName,
        serviceInstanceName: "testvmc01",
        isOperator: true,
      });
    }
    // get users - secure
    if (url.endsWith("/users") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      return ok(users);
    }
    // get users - secure
    if (url.endsWith("/qci") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      return ok(qci);
    }

    // get auditLogs
    if (url.endsWith("/auditLogs/query") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      return ok(auditLogs);
    }

    //get notes
    if (url.includes("/notes") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(notes);

      return ok(notes);
    }

    if (url.endsWith("/notes") && opts.method === "PUT") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      var note = JSON.parse(opts.body);
      fakeLog("Adding notes ");

      notes.push(note);
      return ok();
    }

    if (url.endsWith("/api/v1/orch/tenants") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(tenants);

      return ok(tenants);
    }
    if (url.endsWith("/api/v1/orch/certificates") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(cacert);

      return ok(cacert);
    }
    if (url.endsWith("/api/v1/orch/supportChannel") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(supportChannel);

      return ok(supportChannel);
    }
    if (url.endsWith("/api/v1/orch/supportChannel") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(opts.body);

      return ok(JSON.parse(opts.body));
    }
    if (url.endsWith("/api/v1/orch/accessConfig/template") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(template);

      return ok(template);
    }

    if (url.endsWith("/SAS/getLogs") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(sasLogs);

      return ok(sasLogs);
    }

    if (url.endsWith("/api/v1/orch/tenants") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      var tenant = JSON.parse(opts.body);
      fakeLog("Adding tenant " + tenant.name);

      tenant.id = tenant.name;
      tenant.status = "NOT_DEPLOYED";
      tenants.push(tenant);
      fakeLog(tenants);
      return ok();
    }

    if (url.endsWith("/api/v1/orch/mobilityProfiles") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(MobilityProfiles);
      return ok(MobilityProfiles);
    }
    if (url.endsWith("/api/v1/orch/radioSites") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(sites);
      return ok(sites);
    }

    // /api/v1/orch/radioGroups
    if (url.endsWith("/radioGroups") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(radioGroups);
      return ok(radioGroups);
    }
    if (url.endsWith("/api/v1/orch/networks") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(networks);

      return ok(networks);
    }
    if (url.endsWith("/api/v1/orch/networks/aaaServerProfile") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(aaaServerProfiles);

      return ok(aaaServerProfiles);
    }
    if (url.endsWith("/api/v1/orch/networks/monitoringSystems") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(monitoringSystem);

      return ok(monitoringSystem);
    }

    if (url.endsWith("/api/v1/orch/apiToken") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      return ok(apiToken);
    }

    if (url.endsWith("/api/v1/orch/apiToken") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(opts.body);
      var token = JSON.parse(opts.body);
      fakeLog("Adding network " + token.name);
      token.id = token.name;
      apiToken.data.push(token);
      fakeLog(apiToken);
      return ok(token);
    }

    if (url.endsWith("/api/v1/orch/networks") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      var network = JSON.parse(opts.body);
      fakeLog("Adding network " + network.name);

      network.id = network.name;
      network.status = "NOT_DEPLOYED";
      networks.push(network);
      fakeLog(networks);
      return ok(networks);
    }
    if (url.endsWith("/api/v1/orch/system/settings") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(systemSettings);

      return ok(systemSettings);
    }

    if (url.endsWith("/api/v1/orch/accessConfig") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(accessConfigs);

      return ok(accessConfigs);
    }
    if (url.endsWith("/api/v1/orch/radios/operations/signByCpi") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      // fakeLog(accessConfigs);
      // let body = JSON.parse(opts.body);
      // fakeLog(body);
      const response = {
        data: [
          {
            cellIndex: 1,
            installCertificationTime: "2019-07-01T00:00:00.000Z",
            signature: "signature",
          },
        ],
      };
      return ok(response);
    }

    if (url.endsWith("/api/v1/orch/h9-edges") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(h9Edges);

      return ok(h9Edges);
    }

    if (url.endsWith("/api/v1/orch/edgePools") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(edgePool);

      return ok(edgePool);
    }

    if (url.endsWith("/api/v1/orch/h9-edges") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      var h9Edge = JSON.parse(opts.body);
      fakeLog("Adding network " + h9Edge.name);

      h9Edge.id = h9Edge.name;
      h9Edge.status = "NOT_DEPLOYED";
      h9Edges.push(h9Edge);
      fakeLog(h9Edges);
      return ok();
    }

    if (url.endsWith("/api/v1/orch/h9-apn") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(h9Apn);

      return ok(h9Apn);
    }

    if (url.endsWith("/api/v1/orch/h9-apn") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      var apn = JSON.parse(opts.body);
      fakeLog("Adding apn " + apn.name);

      apn.id = apn.name;
      apn.status = "NOT_DEPLOYED";
      h9Apn.push(apn);
      fakeLog(h9Apn);
      return ok();
    }

    // order
    if (url.endsWith("/api/serviceOrdering/v4/serviceOrder") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(orders);

      return ok(orders);
    }

    if (url.endsWith("/api/serviceOrdering/v4/serviceOrder") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      var order = JSON.parse(opts.body);
      fakeLog("Adding order " + order.id);

      order.state = "acknowledged";
      orders.push(order);
      fakeLog(orders);
      return ok();
    }

    // subscribers
    if (url.endsWith("/api/v1/orch/devices") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      subscribersToShow = ++subscribersToShow % 2;
      return ok(subscribersToShow === 0 ? subscribers0 : subscribers1);
    }
    if (url.endsWith("/api/v1/orch/devices/eud-7dcfef06-484c-4725-b303-a143c9e01619") && opts.method === "PUT") {
      if (!isLoggedIn) return unauthorised();
      return reject(errors);
      // return ok(subscribers);
    }
    // subscribers
    if (url.endsWith("/api/v1/orch/deviceGroups") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(deviceGroup);

      return ok(deviceGroup);
    }
    if (url.includes("/api/v1/orch/deviceGroups/deviceGroup-") && opts.method === "DELETE") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(deviceGroup);

      return reject(errors);
    }

    // subscribers metrics
    if (url.endsWith("/api/v1/orch/devices/metrics") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();

      return ok(subscriberMetrics);
    }

    if (url.includes("/api/v1/orch/devices") && url.endsWith("flows") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();

      return ok(FlowRecords);
    }

    if (url.includes("/api/v1/orch/hardware/query") && opts.method === "POST") {
      fakeLog(harwareInventory);
      return ok(harwareInventory);
    }

    if (url.endsWith("/api/v1/orch/devices") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      var subscriber = JSON.parse(opts.body);
      fakeLog("Adding subscriber ");

      order.state = "NOT_DEPLOYED";
      subscribers0.push(subscriber);
      subscribers1.push(subscriber);
      return ok();
    }

    if (url.endsWith("/api/v1/orch/devices") && opts.method === "PUT") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      subscriber = JSON.parse(opts.body);
      fakeLog("Adding subscriber ");

      order.state = "NOT_DEPLOYED";

      subscribers0.push(subscriber);
      subscribers1.push(subscriber);
      return ok();
    }

    // grafana
    if (url.endsWith("/api/v1/orch/grafana") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(grafana);

      return ok(grafana);
    }

    // edges
    if (url.endsWith("/api/v1/orch/edges") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(edges);
      // return badGateway()
      return ok(edges);
    }

    if (url.endsWith("/api/v1/orch/edges") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      var edge = JSON.parse(opts.body);
      fakeLog("Adding edge ");

      edge.state = "NOT_DEPLOYED";
      edges.push(edge);
      fakeLog(edges);
      return ok();
    }

    // policies
    if (url.endsWith("/api/v1/orch/applications") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(policies);

      return ok(policies);
    }
    if (url.includes("/api/v1/orch/applications/application-") && opts.method === "DELETE") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(errors);

      return reject(errors);
    }

    if (url.endsWith("/api/v1/orch/applications") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      var policy = JSON.parse(opts.body);
      fakeLog("Adding policy");

      policy.state = "NOT_DEPLOYED";
      //policies.push(policy);
      fakeLog(policies);
      return ok();
    }
    if (url.includes("/api/v1/orch/policies") && opts.method === "PUT") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      policy = JSON.parse(opts.body);
      fakeLog("Adding Policy Flow Rule");

      //policy.state = "Adding Policy Flow Rule";
      for (var i = 0; i < policies.length; i++) {
        if (policies[i].id === policy.id) {
          policies[i].flowRules = policy.flowRules;
        }
      }

      //policies.push(policy);
      fakeLog(policies);
      return ok();
    }
    if (url.includes("/api/v1/orch/policies") && opts.method === "DELETE") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      policy = JSON.parse(opts.body);
      fakeLog("Deleting Policy Flow Rule");

      for (i = 0; i < policies.length; i++) {
        if (policies[i].id === policy.id) {
          policies[i].flowRules = policy.flowRules;
        }
      }

      //policies.push(policy);
      fakeLog(policies);
      return ok();
    }

    // dnn service
    if (url.endsWith("/api/v1/orch/dnn") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(dnn);

      return ok(dnn);
    }

    if (url.endsWith("/api/v1/orch/dnn") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      var DNN = JSON.parse(opts.body);
      fakeLog("Adding APN/DNN ");

      DNN.state = "NOT_DEPLOYED";
      dnn.push(DNN);
      fakeLog(dnn);
      return ok();
    }

    if (url.includes("/api/v1/orch/dnn") && opts.method === "PUT") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      DNN = JSON.parse(opts.body);
      fakeLog("Updating Dnn");

      if (dnn.id === DNN.id) {
        dnn.name = DNN.name;
      }

      fakeLog(dnn);
      return ok();
    }

    // Diagnostics-Ran
    if (url.endsWith("/api/v1/orch/diagnostics/ranSimulators") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(diagnosticsRan);

      return ok(diagnosticsRan);
    }

    if (url.endsWith("/api/v1/orch/diagnostics/ranSimulators") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      var diagnosticRan = JSON.parse(opts.body);

      if (diagnosticRan.status === "") {
        fakeLog("Adding Ran simulator ");
        diagnosticsRan.status = "NOT_DEPLOYED";
        diagnosticsRan.push(diagnosticRan);
      } else {
        fakeLog("updating Ran status to Restart enodeb");
        if (diagnosticsRan.id === diagnosticRan.id) {
          diagnosticsRan.status = diagnosticRan.status;
        }
      }

      fakeLog(diagnosticsRan);
      return ok();
    }

    if (url.includes("/api/v1/orch/diagnostics/ranSimulators") && opts.method === "DELETE") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      diagnosticRan = JSON.parse(opts.body);
      fakeLog("Deleting the ran simulator");

      fakeLog(diagnosticsRan);
      return ok();
    }
    if (url.includes("/users/1") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      return ok(userInfo);
    }
    // Diagnostics-Ue
    if (url.endsWith("/api/v1/orch/diagnostics/ueSimulators") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(diagnosticsUe);

      return ok(diagnosticsUe);
    }
    if (url.endsWith("/api/v1/orch/qosprofiles") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(QoSData);

      return ok(QoSData);
    }
    if (url.includes("/api/v1/orch/qosprofiles/qosProfile-") && opts.method === "DELETE") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(errors);
      return reject(errors);
      //return ok(QoSData);
    }
    if (url.endsWith("/api/v1/orch/qosprofiles") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(QoSData);

      return reject(errors);
    }
    if (url.endsWith("/api/v1/orch/zones") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(VMZData);

      return ok(VMZData);
    }
    if (url.includes("/api/v1/orch/zones/zone-") && opts.method === "DELETE") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(errors);

      return reject(errors);
    }
    if (url.endsWith("/api/v1/orch/diagnostics/ueSimulators") && opts.method === "POST") {
      fakeLog(opts.body);
      var diagnosticUe = JSON.parse(opts.body);
      fakeLog("Creating Ue simulator");

      diagnosticUe.id = "4n34jnt3kj5-gttt";
      diagnosticUe.state = "UE_NOT_DEPLOYED";
      diagnosticsUe.data.push(diagnosticUe);
      fakeLog(diagnosticsUe);
      return ok();
    }
    if (url.endsWith("/api/v1/orch/edges/metrics") && opts.method === "POST") {
      fakeLog("edges/metrics=====>", opts.body);

      return ok(edgeMetrics);
    }

    if (url.endsWith(`/api/v1/orch/operations`) && opts.method === "POST") {
      const operation = JSON.parse(opts.body);
      fakeLog("operations=====>", operation);
      const data = {
        ...operation,
        output: [
          {
            key: "url",
            value: "./test/test.txt",
          },
          {
            key: "fileName",
            value: "Text.txt",
          },
          {
            key: "createdAt",
            value: moment().unix(),
          },
          {
            key: "fileSize",
            value: 56000,
          },
        ],
      };

      return ok(data);
    }

    const testOperation = TechBundleOperationData;
    const packetCaptureResp = PacketCapture;

    if (url.endsWith(`/api/v1/orch/operations/query`) && opts.method === "POST") {
      const req = JSON.parse(opts.body);
      // testOperation = { ...testOperation,
      //     "objectId" : operation.objectId,
      // }
      let res = {
        data: testOperation.data.filter((operation) => operation.objectId === req.objectId),
      };
      if (req.operationType === "EXPORT") {
        res = {
          data: exportOperation.data.filter((operation) => operation.operationType === req.operationType),
        };
      }
      if (req.operationType === "START_PACKET_CAPTURE") {
        res = packetCaptureResp;
      }
      if (req.operationType === "SUPPORT_CHANNEL") {
        res = supportChannelStatus;
      }
      fakeLog("operationsquery=====>", req, res);

      return ok(res);
    }

    //download tech
    if (url.endsWith("/api/v1/orch/edges/operations/downloadTech") && opts.method === "POST") {
      fakeLog("operations/downloadTech=====>", opts.body);

      return ok(operation);
    }

    if (url.includes("/api/v1/orch/edges/operations/downloadTech/") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(operation);

      return ok(operation);
    }
    if (url.endsWith("/api/v1/orch/operator/metrics") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(carrierStatus);
      // return badGateway()
      return ok(carrierStatus);
    }

    if (url.includes("/api/v1/orch/operators/query") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(operators);

      return ok(operators);
    }
    if (url.includes("/api/v1/orch/carriergatewayradios/query") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(connectedRadios);

      return ok(connectedRadios);
    }
    if (url.includes("/api/v1/orch/carriergatewaydevices/query") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(connectedEdgeDevices);

      return ok(connectedEdgeDevices);
    }

    if (url.includes("/api/v1/orch/edges/") && opts.method === "DELETE") {
      return reject(errors);
    }
    if (url.endsWith("/api/v1/orch/edges/metrics/aggregate") && opts.method === "POST") {
      return ok(dashboardMetrics);
    }
    if (url.endsWith("/api/v1/orch/radios/metrics/aggregate") && opts.method === "POST") {
      return ok(radioStats);
    }
    if (url.endsWith("/api/v1/orch/radios/metrics") && opts.method === "POST") {
      return ok(radioMetrics);
    }
    if (url.endsWith("/api/v1/orch/radios/radio-fc52ac75-a301-43f9-8857-1607cd362c4e") && opts.method === "PUT") {
      return reject(errors);
      //return ok(radioStats);
    }
    if (url.includes("/api/v1/orch/diagnostics/ueSimulators") && opts.method === "PUT") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      diagnosticUe = JSON.parse(opts.body);
      fakeLog("Updating the ue simulator status");

      if (diagnosticsUe.id === diagnosticUe.id) {
        diagnosticsUe.status = diagnosticUe.status;
      }
      fakeLog(diagnosticsUe);
      return ok();
    }
    if (url.includes("/api/v1/orch/diagnostics/ueSimulators") && opts.method === "DELETE") {
      if (!isLoggedIn) return unauthorised();

      fakeLog(opts.body);
      diagnosticUe = JSON.parse(opts.body);
      fakeLog("Deleting the ue simulator");

      fakeLog(diagnosticsUe);
      return ok();
    }
    //service Controllers
    if (url.endsWith("/api/v1/orch/serviceController") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(serviceControllers);
      //return badGateway()

      return ok(serviceControllers);
    }

    // radios
    if (url.endsWith("/api/v1/orch/radios") && opts.method === "GET") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(radios);
      // return badGateway()
      return ok(radios);
    }

    if (url.endsWith("/api/v1/orch/alarms/query") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(alarms);
      // return badGateway()
      return ok(alarms);
    }
    if (url.endsWith("/api/v1/orch/events/query") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(events);
      // return badGateway()
      return ok(events);
    }
    if (url.endsWith("/api/v1/orch/logs/query") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(logs);
      // return badGateway()
      return ok(logs);
    }
    if (url.endsWith("/api/v1/orch/operatorRadios/query") && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      fakeLog(operatorRadios);
      // return badGateway()
      return ok(operatorRadios);
    }

    if (url.includes(`/api/v1/orch/operators/query`) && opts.method === "POST") {
      if (!isLoggedIn) return unauthorised();
      const req = JSON.parse(opts.body);
      fakeLog("operators=====>", req);
      return ok(operators);
    }

    // pass through any requests not handled above
    return realFetch(url, opts)
      .then((response) => resolve(response))
      .catch((error) => {
        fakeLog(error);
        return reject(error);
      });

    // private helper functions

    function ok(body) {
      resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(body)) });
    }

    function unauthorised() {
      resolve({ status: 401, text: () => Promise.resolve(JSON.stringify({ message: "Unauthorised" })) });
    }
  }, 500);
}

// Enable by commenting line below and uncommenting line above. Remember to disable once work is done.
//const fakeLog = new ConsoleLogger('Backend').;
const fakeLog = function () { };
