import "../../assets/index.css";
import { jsxs as d, jsx as t } from "react/jsx-runtime";
import m from "react";
import { Menu as _ } from "@mui/material";
const h = "_select_bw9q8_4", b = "_label_bw9q8_12", v = "_menu_bw9q8_17", w = "_option_bw9q8_36", f = "_selected_bw9q8_47", g = "_value_bw9q8_51", n = {
  select: h,
  label: b,
  menu: v,
  option: w,
  selected: f,
  value: g
}, C = (e) => {
  var i;
  const [s, a] = m.useState(null), o = !!s, u = (l) => {
    a(l.currentTarget);
  }, c = e.options.find((l) => l.value === e.value);
  return /* @__PURE__ */ d("div", { className: n.container, style: e.style, children: [
    /* @__PURE__ */ t("label", { className: n.label, children: e.label }),
    /* @__PURE__ */ d("div", { id: e.id, className: n.select, onClick: u, children: [
      /* @__PURE__ */ t("span", { className: n.value, children: c == null ? void 0 : c.label }),
      /* @__PURE__ */ t("span", { children: /* @__PURE__ */ t("svg", { width: 16, style: {
        transform: o ? "rotate(180deg)" : "rotate(0deg)",
        fill: ((i = e.style) == null ? void 0 : i.color) ?? "black"
      }, height: 15, focusable: "false", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", children: /* @__PURE__ */ t("path", { d: "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" }) }) })
    ] }),
    /* @__PURE__ */ t(_, { className: n.menu, open: o, anchorEl: s, onClose: () => a(null), children: e.options.map((l) => {
      const r = l.value === e.value;
      return /* @__PURE__ */ t(
        "div",
        {
          onClick: () => {
            e.onChange(l.value), a(null);
          },
          className: n.option + " " + (r ? n.selected : ""),
          children: l.label
        },
        l.value
      );
    }) })
  ] });
};
export {
  C as default
};
