import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SyncronizationProfile } from "~/types/SyncProfile";
import { RootState } from "..";
import { synchronizationService } from "~/services/mobility-service";

type initState = {
  open: boolean;
  current: SyncronizationProfile | null;
  data: SyncronizationProfile[];
};

const initialState: initState = {
  open: false,
  current: null,
  data: [],
};

export const fetchSyncProfiles = createAsyncThunk("syncProfile/fetchSyncProfiles", async () => {
  const data = await synchronizationService.getAll();
  return data;
});
const syncProfileSlice = createSlice({
  name: "syncProfile",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setValues: (state, action: PayloadAction<SyncronizationProfile | null>) => {
      state.current = action.payload;
    },
    updateData: (state, action: PayloadAction<SyncronizationProfile>) => {
      const index = state.data.findIndex((item) => item.id === action.payload.id);
      state.data[index] = action.payload;
    },
    delete: (state, action: PayloadAction<string>) => {
      const index = state.data.findIndex((item) => item.id === action.payload);
      state.data = state.data.filter((_, i) => i !== index);
    },
    setData: (state, action: PayloadAction<SyncronizationProfile[]>) => {
      state.data = action.payload;

      const current = action.payload.find((item) => item.id === state.current?.id);
      if (current) {
        state.current = current;
      }
    },
    addData: (state, action: PayloadAction<SyncronizationProfile>) => {
      state.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSyncProfiles.fulfilled, (state, action) => {
      state.data = action.payload;

      // Sync current with latest data
      if (state.current && !state.open) {
        const current = action.payload.find((item) => item.id === state.current?.id);
        if (current) {
          state.current = current;
        }
      }
    });
  },
});

export const syncProfileActions = syncProfileSlice.actions;
export default syncProfileSlice.reducer;
export const syncProfileState = (state: RootState) => state.syncProfile.current;
export const syncProfileData = (state: RootState) => state.syncProfile.data;
export const syncProfileOpen = (state: RootState) => state.syncProfile.open;
