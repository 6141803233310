import d from "moment-timezone";
function f(n, m, t) {
  const o = d(), r = m ?? o.unix(), e = n.match(/(\d+)([a-zA-Z]+)/);
  if (!e)
    throw new Error(`Invalid interval: ${n}`);
  const c = parseInt(e[1]), s = e[2].toLowerCase(), a = o.subtract(c, s).unix();
  if (t) {
    const i = Math.floor(a / t) * t, T = Math.ceil(r / t) * t;
    return { startTime: i, endTime: T };
  }
  return { startTime: a, endTime: r };
}
export {
  f as getIntervalTime
};
