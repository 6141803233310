import "./assets/style.css";
const e = "_value_1dse4_3", _ = "_valueText_1dse4_42", s = "_label_1dse4_47", o = "_multiChips_1dse4_57", t = "_chip_1dse4_64", n = "_menu_1dse4_80", l = "_options_1dse4_84", d = "_input_1dse4_101", i = "_error_1dse4_129", u = "_disabled_1dse4_133", c = "_group_1dse4_142", p = "_option_1dse4_84", a = "_selected_1dse4_169", r = "_icon_1dse4_173", m = {
  value: e,
  "value-outlined": "_value-outlined_1dse4_19",
  "value-filled": "_value-filled_1dse4_27",
  "value-none": "_value-none_1dse4_35",
  valueText: _,
  label: s,
  multiChips: o,
  chip: t,
  menu: n,
  options: l,
  input: d,
  error: i,
  disabled: u,
  group: c,
  option: p,
  selected: a,
  icon: r
};
export {
  m as s
};
