import flat from "flat";
import * as XLSX from "xlsx";

function unflattenData(data: Array<any>) {
  const unflattened: Array<any> = [];
  data.forEach((row) => {
    const unflattenedRow = flat.unflatten(row);
    unflattened.push(unflattenedRow);
  });
  return unflattened;
}

export function flattenData(data: Array<any>) {
  const flattened: Array<any> = [];
  data.forEach((row) => {
    const flattenedRow = flat.flatten(row);
    flattened.push(flattenedRow);
  });
  return flattened;
}

export const importfromExcel = (file: File) => {
  try {
    const reader = new FileReader();
    const sheetData: any = {};
    reader.readAsArrayBuffer(file);

    return new Promise((resolve, reject) => {
      reader.onloadend = function (e) {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });

        const sheet_name_list = workbook.SheetNames;
        sheet_name_list.forEach(async (y) => {
          const worksheet = workbook.Sheets[y];
          const raw_data = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          const data = unflattenData(raw_data);
          sheetData[y] = data;
        });
        resolve(sheetData);
      };
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const exportToExcel = (data: any, fileName: string) => {
  try {
    const wb = XLSX.utils.book_new();
    Object.keys(data).forEach((sheetName) => {
      const dataToExport = data[sheetName];
      const flatten = flattenData(dataToExport);
      const ws = XLSX.utils.json_to_sheet(flatten);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    });
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    return new File([blob], fileName, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export function decodeJWTToken(token: string) {
  if (!token) return null;
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}
