import { jsx as e } from "react/jsx-runtime";
import { Avatar as h } from "@mui/material";
function m(t) {
  const { width: a, height: o, sx: r, children: i, ...s } = t;
  return /* @__PURE__ */ e(
    h,
    {
      sx: [{ width: a, height: o }, ...Array.isArray(r) ? r : [r]],
      ...s,
      children: i
    }
  );
}
export {
  m as Avatar
};
