/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */

import moment from "moment";

/**
 * Calculates start and end time based on interval
 * @param interval interval string
 * @param rounding rounding in seconds
 * @returns start and end time in unix timestamp format - seconds
 * @example
 * getIntervalTime("15min") // {startTime: 1626951900, endTime: 1626953700}
 * getIntervalTime("1hour") // {startTime: 1626948000, endTime: 1626953700}
 */
function getIntervalTime(interval: string , rounding?: number) {
  const date = moment();
  const endTime = date.unix();

  // Parse the interval string using a regular expression
  const match = interval.match(/(\d+)([a-zA-Z]+)/);
  if (!match) {
      throw new Error(`Invalid interval: ${interval}`);
  }

  const amount = parseInt(match[1]);
  const unit = match[2].toLowerCase();

  // Subtract the specified amount of time from the current date
  const startTime = date.subtract(amount, unit as moment.unitOfTime.DurationConstructor).unix();

  if (rounding) {
      const roundedStartTime = Math.floor(startTime / rounding) * rounding;
      const roundedEndTime = Math.ceil(endTime / rounding) * rounding;
      return { startTime: roundedStartTime, endTime: roundedEndTime };
  }
  
  return { startTime, endTime };
}

/**
 * Calculates resolution based on start and end time
 * @param startTime in unix timestamp format - seconds
 * @param endTime in unix timestamp format - seconds
 * @param samples number of samples (default 1000)
 * @returns resolution in seconds - minimum 1 second
 */
function getResolution(startTime: number, endTime: number, samples = 1000) {
  let resolution = (endTime - startTime) / samples;
  if (resolution > 5) {
    resolution = Math.ceil(resolution / 5) * 5;
  }
  // Minimum resolution is 1 second
  resolution = Math.floor(Math.max(resolution, 1));
  return resolution;
}

function getAggregationPeriod(aggregationPeriod: string): number {
  // const currentOffsetInMinutes = moment().utcOffset();
  // timestamp += currentOffsetInMinutes*60*1000;
  const periods = {
    "15 Minutes": 15 * 60,
    "1 Hour": 60 * 60,
    "1 Day": 24 * 60 * 60,
    "1 Week": 7 * 24 * 60 * 60,
    "1 Month": 30 * 24 * 60 * 60
  };

  const period = periods[aggregationPeriod as keyof typeof periods] || periods["15 Minutes"]; // Default to 15 minutes
  return period;
}

type UtilisationUnitType = "Byte" | "Bytes" | "KB" | "MB" | "GB" | "TB";

/**
 * Converts a value from bytes to the optimum value and unit.
 * @param {number} bytes - The value to be converted.
 * @returns {value: number, unit: UtilisationUnitType} - The converted value and its unit.
 * @description
 *   - Calculates the index optimum unit using log base 1024.
 *   - It them converts the byte value to the value in the optimum value
 */
function getFormattedBytes(bytes: number): { value: number; unit: UtilisationUnitType } {
  if (bytes < 1) return { value: bytes, unit: "Bytes" };
  else if(bytes === 1) return { value: 1, unit: "Byte" };

  const k = 1024;
  const sizes: UtilisationUnitType[] = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const value = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

  return { value: value, unit: sizes[i] };
}

export const metricHelper = {
  getIntervalTime,
  getResolution,
  getAggregationPeriod,
  getFormattedBytes,
};
