/**
 * Copyright Highway9 Networks Inc.
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import RadioGlobalConfig, { pciRange } from "~/types/globalRadioConfig";
import { RootState } from "..";
type radioConfig = {
  editRadioConfig: RadioGlobalConfig;
  stateData: RadioGlobalConfig;
};
export const defaultData: RadioGlobalConfig = {
  borderRadioConfig: {
    pciRange: {
      start: undefined,
      end: undefined,
    },

    bandwidth: 20,
    earfcn: ["" as string],

  },
  nonBorderRadioConfig: {
    pciRange: {
      start: undefined,
      end: undefined,
    },
    bandwidth: 20,
    earfcn: ["" as string],
  },
};
const initialState: radioConfig = {
  editRadioConfig: defaultData,
  stateData: defaultData,
};

const radioConfigSlice = createSlice({
  name: "radioEdit",
  initialState,
  reducers: {
    setRadioConfigData: (state, action: PayloadAction<RadioGlobalConfig>) => {
      state.stateData = action.payload;
    },
    setRadioConfigEditData: (state, action: PayloadAction<RadioGlobalConfig>) => {
      state.editRadioConfig = action.payload;
    },
    setPCIRange: (state, action: PayloadAction<{ type: string; value: string }>) => {
      const { type, value } = action.payload;
      state.editRadioConfig.borderRadioConfig.pciRange[type as keyof pciRange] = value ? parseInt(value) : value;
    },
    setNonBoraderPCIRange: (state, action: PayloadAction<{ type: string; value: string }>) => {
      const { type, value } = action.payload;
      state.editRadioConfig.nonBorderRadioConfig.pciRange[type as keyof pciRange] = value ? parseInt(value) : value;
    },
    // setRadioInfluence: (state, action: PayloadAction<{ type: string; value: string }>) => {
    //   const { type, value } = action.payload;
    //   state.editRadioConfig.influenceRadius[type as keyof influenceRadius] = value ? parseInt(value) : value;
    // },
    setFrequency: (state, action: PayloadAction<{ type: string; value: number | string; index: number }>) => {
      const { type, value, index } = action.payload;
      if (type === "bandwidth") {
        state.editRadioConfig.borderRadioConfig[type] = typeof value === 'string' ? parseInt(value) : value
        state.editRadioConfig.borderRadioConfig.earfcn = []
      } else if (type === "earfcn") {
        state.editRadioConfig.borderRadioConfig[type][index] = value;
      }
    },
    setNonBorderFrequency: (state, action: PayloadAction<{ type: string; value: number | string; index: number }>) => {
      const { type, value, index } = action.payload;
      if (type === "bandwidth") {
        state.editRadioConfig.nonBorderRadioConfig[type] = typeof value === 'string' ? parseInt(value) : value
        state.editRadioConfig.nonBorderRadioConfig.earfcn = []
      } else if (type === "earfcn") {
        state.editRadioConfig.nonBorderRadioConfig[type][index] = value;
      }
    },
    addEARFCN: (state) => {
      if (state.editRadioConfig.borderRadioConfig.earfcn)
        (state.editRadioConfig.borderRadioConfig.earfcn as (string | null)[]).push(null)
      else
        state.editRadioConfig.borderRadioConfig.earfcn = []
    },
    addNonBorderEARFCN: (state) => {
      if (state.editRadioConfig.nonBorderRadioConfig.earfcn)
        (state.editRadioConfig.nonBorderRadioConfig.earfcn as (string | null)[]).push(null)
      else
        state.editRadioConfig.nonBorderRadioConfig.earfcn = []
    },
    deleteEARFCN: (state, action: PayloadAction<number>) => {
      state.editRadioConfig.borderRadioConfig.earfcn =
        state.editRadioConfig.borderRadioConfig.earfcn.filter((_, i) => i !== action.payload) as number[];
    },
    deleteNonBorderEARFCN: (state, action: PayloadAction<number>) => {
      state.editRadioConfig.nonBorderRadioConfig.earfcn =
        state.editRadioConfig.nonBorderRadioConfig.earfcn.filter((_, i) => i !== action.payload) as number[];
    },
  },
});

export default radioConfigSlice.reducer;
export const radioConfigActions = radioConfigSlice.actions;

export const radioConfigData = (state: RootState) => state.radioConfig.stateData;
export const radioConfigEditData = (state: RootState) => state.radioConfig.editRadioConfig;
