/**
 * @ Copyright (C) 2022-present Highway 9 Networks.
 */

import { RootState, store } from "~/store";

interface FillTimeSeriesDataOptions {
  data: number[][];
  startTime: number;
  endTime: number;
  interval?: number;
  modifier?: (val: any) => any;
  fillType?:
    | "zero"
    | "average"
    | "last"
    | "first"
    | "previous"
    | "next"
    | "average-previous-next"
    | "max"
    | "min"
    | "none"
    | "null";
  name?: string;
}

/**
 *  This function is used to fill the time series data with 0 values
 * @param data - the data to be filled
 * @param startTime - the start time of the data
 * @param endTime - the end time of the data
 * @param interval - the interval of the data in seconds (default is 1 day)
 * @param modifier - a function to modify the value of the data (default is identity function)
 * @param fillType - the type of filling to be done (default is 'zero')
 * @param name - the name of the data
 * @returns the filled data with time series format (array of [time, value]) in milliseconds
 */

export function fillTimeSeriesData({
  data,
  startTime,
  endTime,
  interval = 60 * 60 * 24,
  modifier = (val: any) => val,
  fillType = "zero",
  name = "",
}: FillTimeSeriesDataOptions): [number, number][] {
  const timeSeriesData: [number, number][] = [];

  // console.log("fillTimeSeriesData", name, "dataCount:", data.length, "startTime:", startTime, "endTime:", endTime, "interval:", interval, "modifier:", modifier, "fillType:", fillType);

  // safety check
  if (data.length === 0) {
    return timeSeriesData;
  }
  if (startTime > endTime) {
    console.log("name:", name, "startTime:", startTime, "endTime:", endTime);
    throw new Error("Start time is greater than end time");
  }
  if (interval <= 0) {
    console.log("name:", name, "interval:", interval);
    throw new Error("Interval must be greater than 0");
  }
  if (startTime > data[0][0]) {
      // console.log("name:",name , "startTime:", startTime, "data[0][0]:", data[0][0]);
      //console.warn('Start time is greater than the first data point', name);
      startTime = data[0][0];
  }
  if (endTime < data[data.length - 1][0]) {
    endTime = data[data.length - 1][0]
  }
  if (data[0][0] > data[data.length - 1][0]) {
    console.log("name:", name, "data[0][0]:", data[0][0], "data[data.length - 1][0]:", data[data.length - 1][0]);
    throw new Error("Data is not sorted");
  }
  if (data[0][0] === data[data.length - 1][0]) {
    // console.log("name:", name, "data[0][0]:", data[0][0], "data[data.length - 1][0]:", data[data.length - 1][0]);
    console.warn("Data has only one point : ", name);
  }

  // console.groupCollapsed(name, "fillTimeSeriesData");

  let counter = 0;
  for (let i = startTime; i <= endTime; i += interval) {
    let time = i;
    let value: number | null = 0;

    // if there is data for this time, use it instead of 0
    if (counter < data.length && data[counter][0] <= time) {
      time = Math.min(data[counter][0], time);
      value = data[counter][1];
      counter++;
    } else {
      switch (fillType) {
        case "zero":
          value = 0;
          break;
        case "average":
          value = data.reduce((acc, val) => acc + val[1], 0) / data.length;
          break;
        case "last":
          value = data[data.length - 1][1];
          break;
        case "first":
          value = data[0][1];
          break;
        case "previous":
          value = data[counter - 1]?.[1] ?? 0;
          break;
        case "next":
          value = data[counter + 1]?.[1] ?? 0;
          break;
        case "average-previous-next":
          if (counter === 0) {
            value = data[counter]?.[1] ?? 0;
            break;
          }
          value = (data[counter - 1]?.[1] ?? 0 + data[counter + 1]?.[1] ?? 0) / 2;
          break;
        case "max":
          value = Math.max(...data.map((val) => val[1]));
          break;
        case "min":
          value = Math.min(...data.map((val) => val[1]));
          break;
        case "null":
          value = null;
          break;
        case "none":
          continue;
      }
    }

    timeSeriesData.push([time * 1000, modifier(value)]);

    // if(name){
    //     console.log(name, "counter:", counter, "time:", time, "value:", value, "data[counter][0]:", data[counter]?.[0], "data[counter][1]:", data[counter]?.[1]);
    // }
  }

  // console.groupEnd();
  return timeSeriesData;
}

export function calcTickInterval(ticks = 6) {
  const _store = store.getState() as RootState;
  const { diff } = _store.utility.time;
  return Math.round(diff / ticks);
}

// // test cases
// let opts = {
//     data: [ [ 1620000000, 1 ], [ 1620000000, 2 ], [ 1620000000, 3 ] ],
//     startTime: 1620000000,
//     endTime: 1620000010,
//     interval: 1,
//     modifier: (val: any) => val * 10,
//     fillType: 'zero'
// }

// console.log("zero", fillTimeSeriesData({...opts, fillType: 'zero'}))
// console.log("average", fillTimeSeriesData({...opts, fillType: 'average'}))
// console.log("last", fillTimeSeriesData({...opts, fillType: 'last'}))
// console.log("first", fillTimeSeriesData({...opts, fillType: 'first'}))
// console.log("previous", fillTimeSeriesData({...opts, fillType: 'previous'}))
// console.log("next", fillTimeSeriesData({...opts, fillType: 'next'}))
// console.log("average-previous-next", fillTimeSeriesData({...opts, fillType: 'average-previous-next'}))
