/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { RadioGroup } from "../types/radioGroup";
import { ISite } from "../types/site";
import APIService from "./APIServices";

const siteAPI = new APIService<ISite>("radioSites");
const radioGroupAPI = new APIService<RadioGroup>("radioGroups");

export const siteService = {
  getSites: siteAPI.getAll,
  addSite: siteAPI.create,
  updateSite,
  deleteSite: siteAPI.delete,

  getRadioGroups: radioGroupAPI.getAll,
  addRadioGroup: radioGroupAPI.create,
  updateRadioGroup,
  deleteRadioGroup: radioGroupAPI.delete,
};

function updateSite(site: ISite) {
  // Remove properties that are not available in the API
  const updatedSite = structuredClone(site);
  delete updatedSite.countAPs;
  delete updatedSite.connectedAPs;
  delete updatedSite.children;
  delete updatedSite.overallStatus;

  return siteAPI.update(updatedSite);
}

function updateRadioGroup(data: RadioGroup) {
  // Remove properties that are not available in the API
  const newObj = structuredClone(data);
  delete newObj.countAPs;
  delete newObj.connectedAPs;
  delete newObj.radioCoordinates;
  delete newObj.radios;
  delete newObj.overallStatus;

  return radioGroupAPI.update(newObj)
}