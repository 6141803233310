import { jsxs as r, jsx as s } from "react/jsx-runtime";
const o = ({
  size: e = 24,
  color: t = "#616161",
  title: l
}) => /* @__PURE__ */ r("svg", { focusable: "false", role: "img", viewBox: "0 0 24 24", style: {
  width: e,
  height: e
}, children: [
  /* @__PURE__ */ s("path", { fill: t, d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6m0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20" }),
  /* @__PURE__ */ s("title", { children: l })
] });
export {
  o as default
};
