import { authenticationService } from "./authentication-service";
// eslint-disable-next-line no-unused-vars

export const supportChannelServices = {
  getSupportChannel,
  setSupportChannelState,
  updateEdgeSupportChannel,
  getEdgeSupportChannel,
};

async function getSupportChannel() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  try {
    const result = await fetch(`/api/v1/orch/supportChannel`, requestOptions);
    const _resp = await result.text();
    // console.log(_resp)
    const _respObj = JSON.parse(_resp);
    const respData = _respObj?.data ? _respObj?.data[0] : null;
    return respData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
function setSupportChannelState(requestBody, method) {
  const id = method === "PUT" ? `/${requestBody.id}` : "";
  const requestOptions = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(requestBody),
  };

  return fetch(`/api/v1/orch/supportChannel${id}`, requestOptions);
}

async function updateEdgeSupportChannel(requestBody) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(requestBody),
  };

  try {
    const resp = await fetch(`/api/v1/orch/operations`, requestOptions);
    const data = await resp.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
function getEdgeSupportChannel(requestBody) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(requestBody),
  };

  return fetch(`/api/v1/orch/operations/query`, requestOptions);
}
