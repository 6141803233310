/**
 * Copyright Highway9 Networks Inc.
 */
import { UE_Simulator } from "~/types/ueSimulator";
import { authenticationService } from "./authentication-service";

export const diagnosticsUeService = {
  getDiagnosticsUe,
  createDiagnosticsUe,
  modifyDiagnosticsUe,
  deleteDiagnosticsUe,
};

async function getDiagnosticsUe() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  try {
    const response = await fetch(`/api/v1/orch/diagnostics/ueSimulators`, requestOptions);
    const text = await response.text();
    const data = text && JSON.parse(text).data;
    return data as UE_Simulator[];
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function createDiagnosticsUe(diagnosticsUe: UE_Simulator) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(diagnosticsUe),
  };

  console.log("Creating Ue simulator... ");
  try {
    return await fetch(`/api/v1/orch/diagnostics/ueSimulators`, requestOptions);
  } catch (error) {
    console.log(error);
    throw error;
  }
}
async function modifyDiagnosticsUe(diagnosticsUe: UE_Simulator) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(diagnosticsUe),
  };

  console.log("Updating Ue simulator... ");
  try {
    return await fetch(`/api/v1/orch/diagnostics/ueSimulators/${diagnosticsUe.id}`, requestOptions);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

function deleteDiagnosticsUe(id: string) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  console.log("Deleting UE with id " + id);
  return fetch(`/api/v1/orch/diagnostics/ueSimulators/${id}`, requestOptions);
}
