import { jsxs as n, jsx as o } from "react/jsx-runtime";
const d = ({
  size: t = 20,
  color: e = "black",
  direction: r = 1
}) => /* @__PURE__ */ n("svg", { width: t, height: t, style: {
  transform: r === 1 ? "rotate(180deg)" : "rotate(0deg)"
}, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [
  /* @__PURE__ */ o("path", { d: "M7.33333 11.3334L4 8.00008L7.33333 4.66675", stroke: e, strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ o("path", { d: "M12.0001 11.3334L8.66675 8.00008L12.0001 4.66675", stroke: e, strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })
] });
export {
  d as default
};
