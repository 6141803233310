// function normalizeDataPoint(data: number, min: number, max: number, targetMin: number, targetMax: number) {
//   return ((data - min) * (targetMax - targetMin)) / (max - min) + targetMin;
// }

export class ColorPaletteGenerator {
  private bands: { [key: string]: { freqRange: number[], earfcnRange: number[] } };
  private numDivision: number;

  constructor(numDivision: number, type = '5G') {
    // check if Bandwidth must be a multiple of 5 and between 5 and 100
    if (numDivision < 5 || numDivision > 100 || numDivision % 5 !== 0) {
      throw new Error("Bandwidth must be a multiple of 5 and between 5 and 100.");
    }

    const bands5g = {
      'N48': { freqRange: [3550, 3700], earfcnRange: [636667, 646666] },
      'N77': { freqRange: [3300, 4200], earfcnRange: [620000, 680000] },
      'N78': { freqRange: [3300, 3800], earfcnRange: [620000, 653332] },
      'B48': { freqRange: [3550, 3700], earfcnRange: [636667, 646666] },
    };

    this.bands = bands5g;

    // const bands4g = {
    //   'B48': { freqRange: [3550, 3700], earfcnRange: [636667, 646666] },
    // };
    // this.bands =  type === '5G' ? bands5g : bands4g;


    this.numDivision = numDivision; // User-specified divisions
  }

  // private generateColors(numColors: number): string[] {
  //   const delta = 300 / numColors;
  //   const colors: string[] = [];
  //   for (let i = 0; i < numColors; i++) {
  //     const hue = 20 + Math.floor((300 / numColors) * i);
  //     const saturation = normalizeDataPoint(i % delta, 0, delta - 1, 40, 70);
  //     const lightness = normalizeDataPoint(i % delta, 0, delta - 1, 40, 70);
  //     colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
  //   }
  //   return colors;
  // }

  private generateColors(numColors: number) {
    // console.log(numColors);
    const colors = [];
    const factor = Math.cbrt(numColors);
    const step = Math.round(225 / (factor - 1));

    for (let r = 0; r < factor; r++) {


      for (let g = 0; g < factor; g++) {

        for (let b = 0; b < factor; b++) {
          const red = Math.max(Math.min(225, Math.round(r * step)), 10);
          const green = Math.max(Math.min(225, Math.round(g * step)), 10);
          const blue = Math.max(Math.min(225, Math.round(b * step)), 10);
          const alpha = 0.8;
          colors.push(`rgba(${red}, ${green}, ${blue}, ${alpha})`);
        }
      }
    }

    const factor2 = Math.round(Math.cbrt(numColors));

    const colors2 = [];
    for (let j = 0; j < factor2; ++j) {
      for (let i = 0; i < colors.length; i += factor2) {
        colors2.push(colors[i + j]);
      }
    }

    return colors2;
  }

  private createGlobalSubBands(): [number, number][] {
    const globalMinFreq = Math.min(...Object.values(this.bands).map(band => band.freqRange[0]));
    const globalMaxFreq = Math.max(...Object.values(this.bands).map(band => band.freqRange[1]));

    const subBands: [number, number][] = [];
    for (let freq = globalMinFreq; freq < globalMaxFreq; freq += this.numDivision) {
      subBands.push([freq, Math.min(freq + this.numDivision, globalMaxFreq)]);
    }
    return subBands;
  }

  public createColorPalette(): { [band: string]: { [subBand: string]: string } } {
    const palette: { [band: string]: { [subBand: string]: string } } = {};
    const globalSubBands = this.createGlobalSubBands();
    const colors = this.generateColors(globalSubBands.length);

    const subBandColorMap: { [subBand: string]: string } = {};
    globalSubBands.forEach((subBand, index) => {
      const subBandKey = `${subBand[0]}-${subBand[1]} MHz`;
      subBandColorMap[subBandKey] = colors[index];
    });

    for (const [band, { freqRange }] of Object.entries(this.bands)) {
      palette[band] = {};

      for (const [subBandKey, color] of Object.entries(subBandColorMap)) {
        const [subBandMin, subBandMax] = subBandKey.split('-').map(s => parseInt(s));
        if (subBandMin >= freqRange[0] && subBandMax <= freqRange[1]) {
          const key = (subBandMin + subBandMax) / 2;
          palette[band][key] = color;
        }
      }
    }

    return palette;
  }
}

// Example usage:
// const numDivision = 20; // User-specified division size, can be any multiple of 10 between 10 and 100
// const colorPaletteGenerator = new ColorPaletteGenerator(numDivision, "4G");
// const palette = colorPaletteGenerator.createColorPalette();

// for (const [band, subBands] of Object.entries(palette)) {
//   console.log(`Band ${band}:`);
//   for (const [subBand, color] of Object.entries(subBands)) {
//     console.log(`  Frequency range ${subBand}: Color ${color}`);
//   }
// }

export function getNearbyNumberFromObj(num: number, list: Record<number, string>): number {
  if (list === undefined || list === null || Object.keys(list).length === 0) {
    return 0;
  }
  const keys = Object.keys(list).map(Number);
  const closest = keys.reduce((prev, curr) => Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev);
  return closest;
}

export const getTextColorFromBackground = (color: string) => {
  let r, g, b;
  if (color?.startsWith("#")) {
    const bigint = parseInt(color.slice(1), 16);
    r = (bigint >> 16) & 255;
    g = (bigint >> 8) & 255;
    b = (bigint & 255);
  } else {
    // Basic handling for named colors
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.fillStyle = color;
      const computedColor = ctx.fillStyle;
      const rgb = computedColor.match(/\d+/g);
      if (rgb) {
        [r, g, b] = rgb.map(Number);
      } else {
        // Default to light color
        return 1;
      }
    } else {
      // Default to light color
      return 1;
    }
  }
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance / 255;
};
