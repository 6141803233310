import React, { useEffect, useMemo } from 'react'
import AlertDialog from '../AlertDialog';
import moment from 'moment-timezone';
import { Icons } from '../GraphicalResources';
import { useAppSelector } from '~/store/hooks';

type Props = {}

const LoginExpiryAlert = (props: Props) => {

    const user = useAppSelector(state => state.auth.user);

    const { passwordExpiryTime } = user ?? { passwordExpiryTime: 0 };

    const [expiry, setExpiry] = React.useState(false);

    const expiryTime = useMemo(() => passwordExpiryTime - moment().unix(), [passwordExpiryTime]);
    const daysLeft = moment.duration(expiryTime, 'seconds').humanize();
    const expiryDate = moment.unix(passwordExpiryTime).format('MMM, Do YYYY');

    useEffect(() => {
        // if password is about to expire in 7 days
        const days7 = 7 * 24 * 60 * 60;
        if (expiryTime > 0 && expiryTime < days7) {
            setExpiry(true);
        }
    }, [expiryTime])

    return (
        <AlertDialog
            open={expiry}
            setOpen={setExpiry}
            title={<div style={{
                display: 'flex',
                alignItems: 'center'
            }}> {Icons.warning} &nbsp; Password expiry due in {daysLeft}</div>}
            message={
                <>
                    Your password will expire in {daysLeft} on {expiryDate}.
                    <br />
                    Do you want to change it now?
                    <br />
                    <br />
                </>
            }
            confirmText='Change Now'
            cancelText='Later'
            onConfirm={() => {
                // get the url 
                const url = window.location.hostname;
                const csp = url.split('.').slice(1).join('.');

                // open CSP page
                window.open(`https://console.${csp}/`, '_blank');

                setExpiry(false);
            }}
        />
    )
}

export default LoginExpiryAlert