
export function eventDrop(color: string, code: string) {
    return (
        <svg viewBox="0 0 27 43" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1 29.5C1 26.6747 1.93731 24.0684 3.51793 21.975C4.07969 21.1934 4.83915 20.3616 5.67984 19.4409C8.59989 16.2429 12.5 11.9715 12.5 5C12.5 4.44772 12.9477 4 13.5 4C14.0523 4 14.5 4.44772 14.5 5C14.5 11.9715 18.4001 16.2429 21.3202 19.4409C22.1609 20.3616 22.9203 21.1934 23.4821 21.975C25.0627 24.0684 26 26.6747 26 29.5C26 36.4036 20.4036 42 13.5 42C6.59644 42 1 36.4036 1 29.5Z"
                fill={color}
            />
            <text
                x="50%"
                y="70%"
                dominant-baseline="middle"
                text-anchor="middle"
                style={{
                    font: "italic 13px sans-serif",
                    fill: "#fff",
                }}
            >
                {code}
            </text>
        </svg>
    );
}

