/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */
import { Alarm, AlarmQuery } from "../types/alarm";
import APIService from "./APIServices";

const alarmAPI = new APIService<Alarm>("alarms");

async function getAlarms(query: AlarmQuery) {
  const data = (await alarmAPI.post("query", query)) as unknown as Alarm[];
  return data;
}

export const alarmService = {
  getAlarms,
};
