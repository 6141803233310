/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */

import { SelectChangeEvent, Theme } from "@mui/material";
import { AllSystemCSSProperties, SxProps } from "@mui/system";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { DateTimeSelector, Grid, ProfileCard, Select, TopBar } from "@h9/ui-lib";
import { authenticationService, userService } from "~/services";
import { Icons } from "~/components/GraphicalResources";
import EditProfile from "./UserProfile/EditProfile";
import { LocationOn } from "@mui/icons-material";
import { API_DOCS, DASHBOARD } from "~/constants/routes";
import { Types } from "~/constants/types";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import {
  selectEndTime,
  selectStartTime,
  selectTimeInterval,
  dashboardActions,
} from "~/store/context/dashboardSlice";
import { siteActions, siteCurrentDashboard, siteData } from "~/store/context/siteSlice";
import { text } from "~/components/TextResources";
import { roleStringFormatter } from "~/helpers/utils";

export type TopBarType = "primary" | "secondary";

export type TopBarPropType = {
  backgroundColor?: AllSystemCSSProperties["backgroundColor"];
  NotificationComponent?: React.ComponentType;
  subtitle?: string;
  title: string;
  type?: TopBarType;
  bodyId?: string;
};

const dateRanges = [
  {
    label: "1 day",
    value: "1day",
  },
  {
    label: "1 week",
    value: "1week",
  },
  {
    label: "1 month",
    value: "1month",
  },
  {
    label: "3 months",
    value: "3month",
  },
  {
    label: "6 months",
    value: "6month",
  },
  {
    label: "1 year",
    value: "1year",
  },
];

export function TopBarNew({ subtitle, title, bodyId }: TopBarPropType) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEditProfileOpen = () => {
    setIsEditOpen(true);
    handleClose();
  };

  const handleEditProfileClose = () => {
    setIsEditOpen(false);
  };

  const userRole = userService.currentUserInfo?.roles?.map(roleStringFormatter).join(", ") ?? "";

  useEffect(() => {
    if (bodyId) {
      const bodyBlock = document.getElementById(bodyId) as HTMLElement;
      bodyBlock.onscroll = function () {
        if (bodyBlock.scrollTop > 50) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };
    }
  }, [bodyId]);

  return (
    <TopBar
      title={title}
      subtitle={subtitle}
      userName={authenticationService.currentUserValue?.userName}
      role={userRole}
      profileCard={
        <ProfileCard
          profile={{
            name: authenticationService.currentUserValue?.userName ?? "",
            role: userRole,
            email: authenticationService.currentUserValue?.email ?? "",
          }}
          menuOptions={[
            {
              icon: Icons.edit,
              text: text.editProfile!,
              onClick: handleEditProfileOpen,
            },
            {
              icon: Icons.code,
              text: text.apiDocs!,
              onClick: () => {
                window.open(API_DOCS, "_blank");
              },
            },
            {
              icon: Icons.logout,
              text: text.logout!,
              style: commonStyles.logoutItem,
              onClick: () => {
                authenticationService.logout();
              },
            },
          ]}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      }
      setAnchorEl={setAnchorEl}
      style={{ boxShadow: scrolled ? "0 2px 4px 0 rgba(0,0,0,.2);" : null }}
    >
      <TopBarChildren />
      {/* {NotificationComponent ? <NotificationComponent /> : <AlertBadgeRegular fontSize={20} />} */}
      <EditProfile state={isEditOpen} handleClose={handleEditProfileClose} />
    </TopBar>
  );
}

const TopBarChildren = () => {
  return (
    <Grid container item sx={commonStyles.rightContainer}>
      {location.pathname === DASHBOARD && (
        <Grid container gap="16px" width="fit-content">
          <TimeSelection />
          <SiteSelection />
        </Grid>
      )}
      {/* {NotificationComponent ? <NotificationComponent /> : <AlertBadgeRegular fontSize={20} />} */}
    </Grid>
  );
};

const commonStyles = {
  rightContainer: {
    width: "fit-content",
    alignItems: "center",
    gap: "16px",
    justifyContent: "flex-end",
    flex: 1,
  },
  logoutItem: {
    color: "#D32F2F !important",
    gap: "8px",
    "& .MuiSvgIcon-root": {
      width: "16px",
      height: "16px",
      color: "#DF3D2D",
    },
  },
} satisfies Record<string, SxProps<Theme>>;

function SiteSelection() {
  const dispatch = useAppDispatch();
  const sites = useAppSelector(siteData);
  const selectedSite = useAppSelector(siteCurrentDashboard);
  const [selectedFilter, setSelectedFilter] = useState<string>( selectedSite?.id || Types.all);

  const siteOptions = useMemo(() => {
    const allSiteOption = {
      id: Types.all,
      label: text.allSites!,
      value: Types.all,
      icon: <LocationOn sx={{ fontSize: "1rem" }} />,
    };

    return [
      allSiteOption,
      ...sites.map(({ id, name }, idx) => ({
        id: id || `site-option-${idx}`,
        label: name,
        value: id || `site-option-val-${idx}`,
        icon: <LocationOn sx={{ fontSize: "1rem" }} />,
      })),
    ];
  }, [sites]);

  const handleFilterChange = (event: SelectChangeEvent) => {
    const selectedSiteId = event.target.value as string;
    const selectedSite = sites.find((site) => site.id === selectedSiteId);
    dispatch(siteActions.setDashboardSelectedSite(selectedSite || null));
    setSelectedFilter(event.target.value as string);
  };

  return (
    <Select
      id="site-selectory"
      variant="filled"
      value={selectedFilter}
      onChange={handleFilterChange}
      options={siteOptions}
      sx={{ marginRight: "8px" }}
    />
  );
}

const TimeSelection = memo(() => {
  const dispatch = useAppDispatch();
  const interval = useAppSelector(selectTimeInterval);

  const startTime = useAppSelector(selectStartTime);
  const endTime = useAppSelector(selectEndTime);

  const handleDateChange = useCallback((startTime: number, endTime: number, range: string): void => {
    dispatch(dashboardActions.setStartTime(startTime));
    dispatch(dashboardActions.setEndTime(endTime));

    if (dateRanges.find(el => el.value === range)) { 
      dispatch(dashboardActions.setTimeInterval({ interval: range }));
    } else {
      dispatch(dashboardActions.setTimeInterval({ interval: 'custom' }));
    }
  }, [dispatch]);
  return (
      <DateTimeSelector
        startTime={startTime}
        endTime={endTime}
        hideTime={true}
        deltaChangePercent={1}
        interval={interval}
        options={dateRanges}
        style={{ color: "#5B5B5B" }}
        onChange={handleDateChange}
      />
  );
});