import React from "react";
import { Route, Redirect } from "react-router-dom";

import { authenticationService } from "../services";

export const AuthRouter = ({ component: Component, ...rest }) => {
  const url = window.location.href;
  sessionStorage.setItem("url", url);

  return (
    <Route
      {...rest}
      render={(props) => {
        authenticationService.validateCurrentUser();
        const currentUser = authenticationService.currentUserValue;

        if (!currentUser) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};
