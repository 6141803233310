/**
 * Copyright Highway9 Networks Inc.
 */
import React, { useState } from "react";
import {
  TextField,
  Grid,
  Typography,
  Button,
  Tooltip,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { trimSpaces } from "../../components/Utils";
import { text } from "../../components/TextResources";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export function CPIDetails(props) {
  const [cpiId, setCpiId] = React.useState("");
  const [cpiName, setCpiName] = React.useState("");
  const [cpiPassword, setCpiPassword] = React.useState("");
  const [filename, setCpiFilename] = React.useState("");
  const [saveCpiDetails, setSaveDetails] = React.useState("always");

  React.useEffect(() => {
    setCpiId(props.user?.cpiDetails?.id || "");
    setCpiName(props.user?.cpiDetails?.name || "");
    setCpiPassword(props.user?.cpiDetails?.password || "");
    setCpiFilename(props.user?.cpiDetails?.keyfile?.name || "");
    setSaveDetails(props.user?.cpiDetails?.savePolicy || "always");

    return () => {
      // console.log("CPIDetails unmounted")
      reset();
    };
  }, [props.user]);

  function reset() {
    setCpiId("");
    setCpiName("");
    setCpiPassword("");
    setCpiFilename("");
  }

  function handleCpiFileChange(event) {
    const cpiKeyFilename = event.target.files[0].name;
    setCpiFilename(cpiKeyFilename);
    // console.log("Filename ", cpiKeyFilename)

    event.preventDefault();
    const reader = new FileReader();
    reader.onload = async (event) => {
      const text = event.target.result;
      var cpikeyfile = {
        name: cpiKeyFilename,
        content: btoa(text),
      };
      props.cpiDetails.keyfile = cpikeyfile;
    };

    reader.readAsBinaryString(event.target.files[0]);
    props.cpiDetails.keyfile.name = cpiKeyFilename;
  }

  function handleCpiNameChange(event) {
    const name = event.target.value;
    setCpiName(name);
    props.cpiDetails.name = name;
  }

  function handleCpiIdChange(event) {
    const id = trimSpaces(event.target.value);
    setCpiId(id);
    props.cpiDetails.id = id;
  }

  function handleCpiPasswordChange(event) {
    const password = trimSpaces(event.target.value);
    setCpiPassword(password);
    props.cpiDetails.password = password;
  }

  function handleSaveDetailsChange(event) {
    setSaveDetails(event.target.value);
    props.cpiDetails.savePolicy = event.target.value;
  }

  function handleClearSettings(event) {
    setCpiId("");
    setCpiName("");
    setCpiPassword("");
    setCpiFilename("");
    props.clearCPIDetails()

  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          variant="standard"
          label="Name"
          type="text"
          id="name"
          required
          value={cpiName || ""}
          onChange={handleCpiNameChange}
          fullWidth
        ></TextField>{" "}
        <br />
        <br />
        <TextField
          autoComplete="cpiID"
          variant="standard"
          label="ID"
          type="text"
          id="id"
          required
          value={cpiId || ""}
          onChange={handleCpiIdChange}
          fullWidth
        ></TextField>{" "}
        <br />
        <br />
        <TextField
          variant="standard"
          label="Password"
          id="id"
          required
          value={cpiPassword || ""}
          onChange={handleCpiPasswordChange}
          fullWidth
          type={showPassword ? "text" : "password"}
          autoComplete="new-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <br />
        <br />
        <div id="upload-box">
          <Typography>CPI Key: {filename}</Typography>
          <input type="file" id="file-selector" accept=".p12" onChange={handleCpiFileChange} />
          <br />
          <br />
        </div>
        <Typography>Save CPI details for future use:</Typography>
        <RadioGroup row value={saveCpiDetails} onChange={handleSaveDetailsChange} component="fieldset">
          <Tooltip title={"CPI Details will be saved only for current session"} arrow={true}>
            <FormControlLabel
              value="session"
              label="Save for current session only"
              control={<Radio style={{ color: "#026950" }} />}
            />
          </Tooltip>
          <Tooltip title={"CPI Details will be saved for future use"} arrow={true}>
            <FormControlLabel value="always" label="Always" control={<Radio style={{ color: "#026950" }} />} />
          </Tooltip>
        </RadioGroup>
        <br />
        <Button variant="contained" autoFocus onClick={handleClearSettings} color="primary">
          {text.clearSettings}
        </Button>
      </Grid>
    </Grid>
  );
}
