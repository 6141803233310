/**
 * Copyright Highway9 Networks Inc.
 */
import { Simulator } from "~/types/simulator";
import { authenticationService } from "./authentication-service";

export const simulatorServices = {
  getSimulators,
  createSimulators,
  deleteSimulator,
};

async function getSimulators() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  try {
    const response = await fetch(`/api/v1/orch/simulators`, requestOptions);
    const text = await response.text();
    const data = text && JSON.parse(text).data;
    return data as Simulator[];
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function createSimulators(data: Simulator) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(data),
  };

  console.log("Creating simulator... ");
  try {
    return await fetch(`/api/v1/orch/simulators`, requestOptions);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

function deleteSimulator(id: string) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  console.log("Deleting simulator " + id);
  return fetch(`/api/v1/orch/simulators/${id}`, requestOptions);
}
