/**
 * EventEmitter - A simple event emitter for TypeScript and JavaScript (ES6+)
 * @version 1.1.0
 */
interface EventEmit<EventMap extends Record<string, Array<any>>> {
  /** Add an event listener */
  on<K extends keyof EventMap>(event: K, callback: Callback<EventMap[K]>): void;
  /** Remove an event listener */
  off<K extends keyof EventMap>(event: K, callback: Callback<EventMap[K]>): void;
  /** Emit / Fire an event */
  emit<K extends keyof EventMap>(event: K, ...args: EventMap[K]): void;
  /** Add an event listener that will be called only once */
  once<K extends keyof EventMap>(event: K, callback: Callback<EventMap[K]>): void;
  /** Remove all listeners and events */
  clear(): void;
  /** Remove all listeners for a specific event */
  removeEvent<K extends keyof EventMap>(event: K): void;
  /** Get all events names */
  getEvents(): string[];
  /** Get the number of events */
  getEventCount(): number;
  /** Get the number of listeners for a specific event */
  getListenerCount<K extends keyof EventMap>(event: K): number;
  /** Get the number of listeners for all events */
  getListenerCountAll(): number;
  /** Check if an event has listeners */
  hasEvent<K extends keyof EventMap>(event: K): boolean;
  /** Check if an event has listeners */
  hasListeners<K extends keyof EventMap>(event: K): boolean;
  /** Get all listeners for a specific event */
  listeners<K extends keyof EventMap>(event: K): Set<Callback<EventMap[K]>>;
}

type Callback<T extends any[]> = (...args: T) => void;

export class EventEmitter<EventMap extends Record<string, Array<any>>> implements EventEmit<EventMap> {
  private eventsListeners: {
    [K in keyof EventMap]?: Set<Callback<EventMap[K]>>;
  } = {};

  on<K extends keyof EventMap>(event: K, callback: Callback<EventMap[K]>) {
    const listeners = this.eventsListeners[event] ?? new Set();
    listeners.add(callback);
    this.eventsListeners[event] = listeners;
    // console.log('[Event]', this.getEvents());
  }

  off<K extends keyof EventMap>(event: K, callback: Callback<EventMap[K]>) {
    const listeners = this.eventsListeners[event];
    if (!listeners) {
      return;
    }
    listeners.delete(callback);
    console.log("[Event]", this.getEvents().join(", "));
  }

  emit<K extends keyof EventMap>(event: K, ...args: EventMap[K]) {
    const listeners = this.eventsListeners[event];
    if (!listeners) {
      console.error("[Event]", "Event not found: " + event.toString());
      return;
    }
    for (const listener of listeners) {
      listener(...args);
    }
    // console.log('[Event]', 'fired: ' + event.toString())
  }

  once<K extends keyof EventMap>(event: K, callback: Callback<EventMap[K]>) {
    const wrapper = (...args: EventMap[K]) => {
      callback(...args);
      this.off(event, wrapper);
    };

    this.on(event, wrapper);
  }

  clear() {
    this.eventsListeners = {};
  }

  removeEvent<K extends keyof EventMap>(event: K) {
    delete this.eventsListeners[event];
    console.log("[Event]", this.getEvents().join(", "));
  }

  listeners<K extends keyof EventMap>(event: K) {
    return this.eventsListeners[event] ?? new Set();
  }

  hasListeners<K extends keyof EventMap>(event: K) {
    return this.listeners(event).size > 0;
  }

  hasEvent<K extends keyof EventMap>(event: K) {
    return this.eventsListeners.hasOwnProperty(event);
  }

  getEvents() {
    return Object.keys(this.eventsListeners);
  }

  getEventCount() {
    return this.getEvents().length;
  }

  getListenerCount<K extends keyof EventMap>(event: K) {
    return this.listeners(event).size;
  }

  getListenerCountAll() {
    let count = 0;
    for (const event in this.eventsListeners) {
      count += this.getListenerCount(event);
    }
    return count;
  }
}

export type EventMap = {
  refreshRadios: [];
  refreshRadioGroups: [];
  refreshSites: [];
  updateGroupAndRadio: [];
  refreshEdges: [];
  refreshEdgePools: [];
  refreshDevices: [];
  refreshDeviceGroups: [];
  // refreshDeviceGroupsAndDevices: [];
  setStartandEndTime: [startTime: number, endTime: number];
  refreshRadioSimulator: [];
  refreshSimulator: [];
  refreshServiceController: [];

  refreshUESimulators: [];
  refreshDNN: [];

  openSnackbar: [{ message: string; severity: "success" | "error" | "warning" | "info" }];
};

const evt = new EventEmitter<EventMap>();

export function eventLog(...msg: any[]) {
  //console.log(`[EventContext]`, ...msg);
}

export default evt;
