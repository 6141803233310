/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { networkService } from "../../services";
import { Network } from "../../types/network";

type initState = {
  open: boolean;
  data: Network | null;
};

const initialState: initState = {
  open: false,
  data: null,
};

export const fetchNetwork = createAsyncThunk("network/fetch", async () => {
  try {
    const result = await networkService.getNetworks();
    const JsonArray = await result.text();
    const networksData = JSON.parse(JsonArray).data;
    // console.log(networksData[0]);
    return networksData[0] as Network;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNetwork.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchNetwork.rejected, (state, action) => {
        console.error(action.error);
      });
  },
});

export const networkActions = networkSlice.actions;
export default networkSlice.reducer;

export const networkOpen = (state: RootState) => state.network.open;
export const networkData = (state: RootState) => state.network.data;
