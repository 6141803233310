import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/**
 *
 * @param {{
 * preFetch?: boolean,
 * value: number | string,
 * index: number | string,
 * }} props
 * @returns
 */
export default function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const preFetch = props.preFetch || false; // set to true to pre-fetch the data
  // if preFetch is true, then fetch the data for the current tab else do nothing
  const noPrefetch = preFetch ? true : value === index;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {noPrefetch && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
