/**
 * @file LoadingX.tsx
 * @description Loading component.
 * @ CopyRight (c) 2022 - present All rights reserved - Highway9 Networks
 */
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useLoading } from "../../store/context/loadingSlice";

export default function LoadingX() {
  const { loading } = useLoading();
  if (!loading) return null;
  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: "9999" }} open={loading}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
}
