import u, { jsxs as g, jsx as e } from "react/jsx-runtime";
import { i as c } from "../../../styled-21c61147.js";
import { r as m } from "../../../createSvgIcon-a5345e94.js";
import "../../../WidgetMetricGraph-0b95dfbe.js";
import { OutlinedInput as M, InputAdornment as I, IconButton as j, TextField as z } from "@mui/material";
import { useState as C } from "react";
import "../../../map-1c5b8782.js";
import "../../../server.browser-ec1ee1ca.js";
import "../Grid/Grid.js";
import { Typography as S } from "../Typography/Typography.js";
import "../../../style.module-3bbdaa05.js";
import "../../DateTimeSelector/index.js";
import "../../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "moment-timezone";
import "../../../style.module-7bdf835c.js";
var a = {}, $ = c;
Object.defineProperty(a, "__esModule", {
  value: !0
});
var f = a.default = void 0, D = $(m()), O = u, P = (0, D.default)(/* @__PURE__ */ (0, O.jsx)("path", {
  d: "M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
}), "Visibility");
f = a.default = P;
var l = {}, R = c;
Object.defineProperty(l, "__esModule", {
  value: !0
});
var v = l.default = void 0, A = R(m()), q = u, T = (0, A.default)(/* @__PURE__ */ (0, q.jsx)("path", {
  d: "M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"
}), "VisibilityOff");
v = l.default = T;
function te(o) {
  const {
    variant: n = "outlined",
    width: s,
    type: h = "text",
    height: d,
    sx: t,
    label: x,
    ...r
  } = o, [i, y] = C(!1), b = () => {
    y(!i);
  }, w = (_) => {
    _.preventDefault();
  };
  return /* @__PURE__ */ g(S, { sx: V, type: "bodyText3", children: [
    /* @__PURE__ */ e("span", { children: x }),
    h == "password" ? /* @__PURE__ */ e(
      M,
      {
        id: "outlined-adornment-password",
        type: i ? "text" : "password",
        endAdornment: /* @__PURE__ */ e(I, { position: "end", children: /* @__PURE__ */ e(
          j,
          {
            "aria-label": "toggle password visibility",
            onClick: b,
            onMouseDown: w,
            edge: "end",
            children: i ? /* @__PURE__ */ e(v, {}) : /* @__PURE__ */ e(f, {})
          }
        ) }),
        sx: [
          { width: s, height: d },
          p[n],
          ...Array.isArray(t) ? t : [t]
        ],
        placeholder: r.placeholder ? r.placeholder : "",
        ...r
      }
    ) : /* @__PURE__ */ e(
      z,
      {
        sx: [
          { width: s, height: d },
          p[n],
          ...Array.isArray(t) ? t : [t]
        ],
        ...r
      }
    )
  ] });
}
const p = {
  outlined: (o) => ({
    borderColor: `${o.palette.grey[600]}`,
    borderRadius: "8px",
    textTransform: "none",
    gap: 3,
    marginTop: "2px !important",
    fontSize: "14px",
    ".MuiInputBase-root": {
      borderRadius: 2
    },
    "&>.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#14141499"
    }
  }),
  filled: () => ({}),
  standard: () => ({})
}, V = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  "& > span": {
    marginLeft: ".5rem"
  }
};
export {
  te as Input
};
