/**
 * Copyright Highway9 Networks Inc.
 */

import { authenticationService } from "./authentication-service";

const getHardwareInventory = async (payload : any) => {
  // const requestBody = payload ? payload : {"filter" :{}}
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(payload),
  };

  try {
    const res = await fetch(`/api/v1/orch/hardware/query`, requestOptions);
    const text = await res.text();
    const data = JSON.parse(text)?.data;

    return data as import("~/types/Hardware").Hardware[];

  } catch (error) {
    console.log("Error : ", error);
    throw error;
  }
};

const getProductInfo = async (productId: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  try {
    const res = await fetch(`/api/v1/orch/product/${productId}`, requestOptions);
    const text = await res.text();
    const parsed = JSON.parse(text);
    const data = parsed?.data ?? parsed;
    return data as import("~/types/Product").Product;

  } catch (error) {
    console.log("Error : ", error);
    throw error;
  }
};

export const hardwareInventoryService = {
  getHardwareInventory,
  getProductInfo,
};
