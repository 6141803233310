/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */
import { Log, LogQuery } from "../types/logs";
import APIService from "./APIServices";

const logAPI = new APIService<Log>("logs");

async function getLogs(query: LogQuery) {
  const data = (await logAPI.post("query", query)) as unknown as Log[];
  return data;
}

export const logServices = {
  getLogs,
};
