/**
 * Copyright Highway9 Networks Inc.
 */
import { ServiceController } from "../types/serviceController";
import APIService from "./APIServices";
import { edgeAPI } from "./edge-service";

const serviceControllerAPI = new APIService<ServiceController>("serviceController");
export const dashboardService = {
  getMetrics: edgeAPI.getAggregateMetrics,
  getControllerSummary: serviceControllerAPI.getAll,
};
