/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { EventData, EventQuery, EventSummary, EventsSummaryQuery } from "~/types/event";
import APIService from "./APIServices";

const eventAPI = new APIService<EventData>("events");

async function getEvents(query: EventQuery) {
  const data = (await eventAPI.post("query", query)) as unknown as EventData[];

  return data;
}

async function getEventSummary(query: EventsSummaryQuery) {
  try {
    const response = await eventAPI.post("summary", query);
    return response as unknown as EventSummary[];
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}

export const eventServices = {
  getEvents,
  getEventSummary
};