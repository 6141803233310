import * as u from "react";
function p(...e) {
  return e.reduce((t, n) => n == null ? t : function(...r) {
    t.apply(this, r), n.apply(this, r);
  }, () => {
  });
}
function w(e, t = 166) {
  let n;
  function c(...r) {
    const i = () => {
      e.apply(this, r);
    };
    clearTimeout(n), n = setTimeout(i, t);
  }
  return c.clear = () => {
    clearTimeout(n);
  }, c;
}
function f(e) {
  return e && e.ownerDocument || document;
}
function I(e) {
  return f(e).defaultView || window;
}
function a(e, t) {
  typeof e == "function" ? e(t) : e && (e.current = t);
}
const l = typeof window < "u" ? u.useLayoutEffect : u.useEffect, d = l;
function T(e) {
  const t = u.useRef(e);
  return d(() => {
    t.current = e;
  }), u.useRef((...n) => (
    // @ts-expect-error hide `this`
    (0, t.current)(...n)
  )).current;
}
function y(...e) {
  return u.useMemo(() => e.every((t) => t == null) ? null : (t) => {
    e.forEach((n) => {
      a(n, t);
    });
  }, e);
}
const s = {};
function h(e, t) {
  const n = u.useRef(s);
  return n.current === s && (n.current = e(t)), n;
}
const m = [];
function E(e) {
  u.useEffect(e, m);
}
class o {
  constructor() {
    this.currentId = null, this.clear = () => {
      this.currentId !== null && (clearTimeout(this.currentId), this.currentId = null);
    }, this.disposeEffect = () => this.clear;
  }
  static create() {
    return new o();
  }
  /**
   * Executes `fn` after `delay`, clearing any previously scheduled call.
   */
  start(t, n) {
    this.clear(), this.currentId = setTimeout(() => {
      this.currentId = null, n();
    }, t);
  }
}
function R() {
  const e = h(o.create).current;
  return E(e.disposeEffect), e;
}
export {
  o as T,
  I as a,
  T as b,
  p as c,
  w as d,
  y as e,
  R as f,
  f as o,
  a as s,
  d as u
};
