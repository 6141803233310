/**
 * Copyright Highway9 Networks Inc.
 */
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { authenticationService } from "../services";
import useErrorsAndWarnings from "../hooks/useErrorsAndWarnings";
import ErrorsComponent from "../components/ErrorsComponent";
import { LoginView } from "@h9/ui-lib";
export function LoginPage() {
  const { errors, warnings, setErrorsAndWarnings, closeAlert, closeAll } = useErrorsAndWarnings();
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [hidePassword, setHidePassword] = useState(true);

  async function handleSubmit(email: string, password: string) {
    setLoadingButton(true);
    try {
      const sessionInfo = await authenticationService.getSessionInfo(email.trim());
      if (!sessionInfo) {
        return;
      }

      await authenticationService.login(email.trim(), password.trim(), sessionInfo.authKey);
      console.log("Logged in.");

      const url = sessionStorage.getItem("url") || "/";
      window.location.replace(url);
    } catch (error : any) {
      console.error(error);
      setErrorsAndWarnings(error.errors, error.warnings);
    } finally {
      setLoadingButton(false);
    }
  }

  async function handleNext(email: string) {
    setLoadingButton(true);
    try {
      const sessionInfo = await authenticationService.getSessionInfo(email);
      if (sessionInfo?.authType === "SAML") {
        window.location.href = sessionInfo.redirectUrl;
        return;
      }
      if (sessionInfo?.authType === "PASSWORD") {
        setHidePassword(false);
      }
    } catch (error) {
      console.log("error, no SAML authType found", error);
      setHidePassword(false);
    } finally {
      setLoadingButton(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      authenticationService
        .getSession()
        .then((response) => {
          if (response.status === 200) {
            const url = sessionStorage.getItem("url") || "/";
            console.log("url: ", url);
            window.location.replace(url);
            //window.location.reload();
          }
        })
        .catch((error) => {
          setLoading(false);
          setErrorsAndWarnings(error.errors, error.warnings);
        });
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div style={{ height: "100vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress size={80} />
      </div>
    );
  }

  function handleForgotPassword() {
    const host = window.location.host;
    const protocol = window.location.protocol;
    const urlHostFragments = host.split(".");
    let redirectUrl = "";
    if (urlHostFragments.length <= 2) {
      // We only have top level domain name e.g. ["higway9networks", "com"]
      redirectUrl = `console.${host}/login`;
    } else {
      urlHostFragments[0] = "console";
      redirectUrl = `${urlHostFragments.join(".")}/login`;
    }

    window.open(`${protocol}//${redirectUrl}`);
  }

  return (
    <LoginView
      errorsComponent={
        <ErrorsComponent errorsData={errors} warningsData={warnings} closeAll={closeAll} closeAlert={closeAlert} />
      }
      hidePassword={hidePassword}
      loading={loadingButton}
      onLogin={handleSubmit}
      onNext={handleNext}
      onForgotPassword={handleForgotPassword}
    />
  );
}
