import { createTheme as e } from "@mui/material";
const i = e({
  breakpoints: {
    values: {
      xs: 300,
      sm: 600,
      md: 900,
      lg: 1500,
      xl: 2e3
    }
  },
  spacing: 4,
  palette: {
    primary: {
      main: "#226A58",
      light: "#ABD9CE",
      dark: "#1A5F4E"
    },
    secondary: {
      main: "#009688"
    },
    success: {
      main: "#E9F0EE"
    },
    aTandT: {
      main: "#067AB4"
    },
    blue: {
      400: "#4B71FF",
      300: "#738DFF",
      200: "#A0AEFF"
    },
    bluegrey: {
      900: "#222325",
      750: "#5C6473",
      700: "#5D6169",
      600: "#747982",
      400: "#C5C8CE",
      100: "#CDD2DB"
    },
    blueViolet: {
      500: "#5158BB",
      300: "#838CD0",
      100: "#C9CDEB"
    },
    deepFuchsia: {
      300: "#CE5ACA",
      200: "#DD8CD9",
      100: "#EABBE8"
    },
    grey: {
      900: "#141414",
      700: "#5B5B5B",
      600: "#878787",
      200: "#EFEFEF",
      100: "#F7F7F7",
      50: "#FFFFFF"
    },
    tealBlue: {
      300: "#4BBFB9",
      100: "#B3E3E1",
      50: "#E1F4F4"
    },
    tMobile: {
      main: "#E20074"
    },
    terraCotta: {
      500: "#DB4C63",
      300: "#E7738F",
      200: "#EE98AE"
    },
    yellowOrange: {
      400: "#FFA600",
      200: "#FFCB78",
      100: "#FFE0AE"
    },
    verizon: {
      main: "#D52B1E"
    }
  },
  transitions: {
    duration: {
      enteringScreen: 200,
      leavingScreen: 100
    }
  },
  components: {
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: !0
        // No more ripple, on the whole application 💣
      }
    },
    MuiButton: {
      defaultProps: {
        size: "small",
        variant: "contained"
      }
    },
    MuiTab: {
      defaultProps: {
        iconPosition: "start"
      },
      styleOverrides: {
        root: {
          minHeight: 32
        }
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: "small"
      }
    },
    // MuiFilledInput: {
    //   styleOverrides: {
    //     input: {
    //       padding: "8px 32px 8px 16px"
    //     },
    //     root: ({ theme }) => ({
    //       backgroundColor: "rgba(255, 255, 255, 0.07)",
    //       border: "1px solid rgba(255, 255, 255, 0.07)",
    //       borderRadius: theme.spacing(4.5),
    //       color: theme.palette.grey["50"],
    //       "&:before": {
    //         borderBottom: "none"
    //       },
    //       "&:after": {
    //         borderBottom: "none"
    //       },
    //       "&:hover:not(.Mui-disabled, .Mui-error):before": {
    //         borderBottom: "none"
    //       }
    //     })
    //   }
    // },
    // MuiSelect: {
    //   styleOverrides: {
    //     icon: {
    //       color: "white"
    //     },
    //     select: {
    //       "&:focus": {
    //         background: "none"
    //       }
    //     }
    //   }
    // },
    // MuiInputLabel: {
    //   styleOverrides: {
    //     root: {
    //       "&.MuiInputLabel-shrink": {
    //         display: "none"
    //       }
    //     }
    //   }
    // },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "1.25rem"
        }
      }
    }
  }
});
export {
  i as theme
};
