/**
 * Copyright Highway9 Networks Inc.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { bandConfigData, OperatorRadioQuery } from "../../types/OperatorQuery";

type initState = {
  open: boolean;
  current: OperatorRadioQuery | bandConfigData | null;
  data: OperatorRadioQuery[];
  error?: string | null;
};

export const initialState: initState = {
  open: false,
  current: null,
  data: [],
};

const operatorRadioSlice = createSlice({
  name: "operatorRadio",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setSelectedRow: (state, action: PayloadAction<OperatorRadioQuery>) => {
      state.current = action.payload;
    },
    setData: (state, action: PayloadAction<OperatorRadioQuery[]>) => {
      state.data = action.payload;
    },
  },
});

export const operatorRadioActions = operatorRadioSlice.actions;
export default operatorRadioSlice.reducer;

export const operatorRadioOpen = (state: RootState) => state.operatorRadio.open;
export const operatorRadioSelectedRow = (state: RootState) => state.operatorRadio.current;
export const operatorRadiosData = (state: RootState) => state.operatorRadio.data;
