import { EdgePool } from "~/types/EdgePool";
import APIService from "./APIServices";
import { authenticationService } from "./authentication-service";
import { Edge } from "~/types/edge";

export const edgeAPI = new APIService<Edge>("edges");
const edgePoolAPI = new APIService<EdgePool>("edgePools");

export const edgeService = {
  getEdges: edgeAPI.getAll,
  // createEdges: edgeAPI.create,
  deleteEdges: edgeAPI.delete,
  updateEdge: edgeAPI.update,
  getMetrics: edgeAPI.getMetrics,
  getAggregateMetrics: edgeAPI.getAggregateMetrics,
  getEdgePool: edgePoolAPI.getAll,
  addEdgePool: edgePoolAPI.create,
  updateEdgePool: edgePoolAPI.update,
  deleteEdgePool: edgePoolAPI.delete,
  downloadTechFile,
};

async function downloadTechFile() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  console.log("downloading tech files for the edge... ");
  try {
    return await fetch(`/api/v1/orch/edges/downloadTech/`, requestOptions);
  } catch (error) {
    console.log(error);
    throw error;
  }
}
