/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { Icons } from "../components/GraphicalResources";
import { text } from "../components/TextResources";

export const DASHBOARD = "/";
export const LOGIN = "/login";
export const EDGES = "/edges";
export const RADIOS = "/radios";
export const DEVICES = "/devices";
export const NETWORKS = "/networks";
export const VMZ = "/vmz";
export const SIMULATORS = "/simulators";
export const SETTINGS = "/settings";
export const PROFILES = "/profiles";
export const AUDITLOGS = "/auditlogs";
export const HARDWARE_INVENTORY = "/hardwareInventory";
export const API_DOCS = "/api/v1/orch/docs";
export const LICENSE_AGREEMENT = "/eula";
export const IT_DASHBOARD = "/monitor";

export const routes = [
  { name: text.dashboard, path: DASHBOARD, icon: Icons.dashboard },
  { name: text.monitor, path: IT_DASHBOARD, icon: Icons.monitor },
  { name: "|", path: null },
  { name: text.edge, path: EDGES, icon: Icons.edge, objectType: "edge" },
  { name: text.RAN, path: RADIOS, icon: Icons.OutdoorRadio, objectType: "radio" },
  { name: text.subscribers, path: DEVICES, icon: Icons.subscribers, objectType: "device" },
  { name: "|", path: null },
  { name: text.DNN, path: NETWORKS, icon: Icons.APN_DNN, objectType: "dnn" },
  { name: text.VMZ, path: VMZ, icon: Icons.VMZ, objectType: "zone" },
  { name: text.Profiles, path: PROFILES, icon: Icons.profiles, objectType: "mobilityProfile" },
  { name: "|", path: null },
  { name: text.simulators, path: SIMULATORS, icon: Icons.simulators, objectType: "simulator" },
  { name: "|", path: null },
  { name: text.administration, path: SETTINGS, icon: Icons.settings, objectType: "network" },
  { name: text.auditLog, path: AUDITLOGS, icon: Icons.logs, objectType: "log" },
  { name: text.hardwareInventory, path: HARDWARE_INVENTORY, icon: Icons.hardwareInventory, objectType: "vmcHardware" },
];

export const bottomRoutes = [{ name: text.apiDocs, path: API_DOCS, icon: Icons.apiDocs }];

export const NEW_ROUTES = [DASHBOARD, LOGIN, IT_DASHBOARD];

export const profileCardRoutes = [
  { name: text.apiDocs, path: API_DOCS, icon: Icons.apiDocs },
  // { name: text.licenseAgreement, path: LICENSE_AGREEMENT, icon: Icons.license },
];
