
/**
 * The `geoDistance` function calculates the distance between two geographical coordinates using the
 * Haversine formula in TypeScript.
 * @param {number} lat1 - Latitude of the first point in degrees
 * @param {number} lon1 - Longitude of the first location in degrees
 * @param {number} lat2 - Latitude of the second point
 * @param {number} lon2 - Longitude of the second point
 * @returns The `geoDistance` function returns the distance in meters between two sets of geographical
 * coordinates (latitude and longitude) provided as input parameters.
 */
export const geoDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6371000; // Radius of the earth in m

  // distance between latitudes and longitudes
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in m
};

// Path: src\helpers\geoDistance.ts
// convert degree to radian
export const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};
