/**
 * Copyright Highway9 Networks Inc.
 */

import moment from "moment";
import { asset } from "../../../assets";
import { DateTimeFormat, svgJSXtoURL } from "../../../helpers/utils";
import { EventData } from "~/types/event";
import { eventDrop } from "~/components/shared/DropIcon";
import { eventMap } from "~/constants/eventMap";

/**
 * This function is used to minimize the matrix data points based on the interval in seconds.
 * @param {Array<Array>} array
 * @param {object} depObj
 * @returns {Array<Array>} 2DArray
 */
export function minimizeArray(
  array: Array<Array<any>>,
  depObj: { [key: string | number]: string },
  interval = 600,
  maxPoints = 300
): Array<Array<any>> {
  // limit check if interval is greater than 1.5 hours then return the array
  if (interval > 5400) {
    const data = lossArray(array, "first", 30, maxPoints);
    return data;
  }

  const newArray: Array<any> = [];
  let prevValue = null;
  let prevRadioID = null;
  let prevTimeStamp = 0;

  for (let i = 0; i < array.length; i++) {
    const currTimeStamp = array[i][0];
    const currValue = array[i][1];
    const radioID = depObj[array[i][0]];
    // console.log("radioID", radioID);
    let pushed = false;

    if (currValue === 1 && prevValue === currValue) {
      if (radioID && radioID !== prevRadioID) {
        newArray.push([currTimeStamp, currValue, radioID]);
        pushed = true;
      }
    } else if (prevValue !== currValue) {
      newArray.push([currTimeStamp, currValue, radioID]);
      pushed = true;
    }

    if (currTimeStamp - prevTimeStamp > interval) {
      if (!pushed) {
        newArray.push([currTimeStamp, currValue, undefined]);
      }
      prevTimeStamp = currTimeStamp;
    }
    prevValue = currValue;
    prevRadioID = radioID;
  }

  if (newArray.length > maxPoints) {
    // console.log("ddd max points min arr c", newArray.length);
    // let data = lossArray(newArray, 'first', 30, maxPoints);
    // console.log("ddd datap new :", data.length);
    // return data;
    return minimizeArray(newArray, depObj, interval + 300, maxPoints);
  }
  return newArray;
}

export function compareObjects(obj1: Object, obj2: Object) {
  //console.time("compareObjects")
  const res = JSON.stringify(obj1) === JSON.stringify(obj2);
  //console.timeEnd("compareObjects")
  return res;
}

/**
 * Reduce the array based on the loss
 * @param {Array} array Array to compare
 * @param {string} lossType - alternate , first
 * @param {number} loss - data loss in percentage for
 * @param {number} maxPoints - max points to be displayed
 * @returns {Array}
 */
function lossArray(array: Array<any>, lossType = "first", loss = 50, maxPoints: number) {
  let maxLength = 0;
  if (maxPoints) {
    maxLength = array.length - maxPoints;
  } else {
    maxLength = (loss / 100) * array.length;
  }
  const inc = calcIndex(loss);
  //console.log('ddd loss ml', maxLength, array.length, maxPoints);
  // lost Type : Alternate , first
  const newArray: Array<any> = [];
  if (lossType === "alternate") {
    for (let i = 0; i < array.length; i++) {
      if (i % inc === 0) {
        continue;
      }
      newArray.push(array[i]);
    }
    return newArray;
  } else if (lossType === "first") {
    const _arr = array.slice(maxLength + 1);
    newArray.push(array[0], ..._arr);
    //console.log('ddd loss arr', newArray.length)
    return newArray;
  }
  if (maxPoints && newArray.length > maxPoints) {
    const extra = newArray.length - maxPoints;
    //console.log("ddd extra", extra);
    const _arr = newArray.slice(extra);
    return _arr;
  }
  return newArray;
}

function calcIndex(val: number) {
  switch (val) {
    case 50:
      return 2;
    case 33:
      return 3;
    case 25:
      return 4;
    case 20:
      return 5;
    case 10:
      return 10;
    default:
      return 1;
  }
}

export function formatTimelineData(metricsData: any, radioMap: { [key: string]: string }) {
  const data = [];
  // let labelCount = 0;
  //console.time('ddd getStatusData');
  for (let i = 0; i < metricsData.ue_connection_state?.length; i++) {
    let value = "";
    let radio = "";
    let color = "#000000";
    let lable = false;
    const radioID = metricsData.ue_connection_state[i][2];
    const zone_name = moment.tz.guess();
    const time = moment.unix(metricsData.ue_connection_state[i][0]).tz(zone_name).format(DateTimeFormat);
    const category = metricsData.ue_connection_state[i][0] * 1000;
    let datalabel = undefined;
    const radioName = radioID ? radioMap[radioID] : undefined;
    // console.log("radioName", radioID,  radioName);

    switch (metricsData.ue_connection_state[i][1]) {
      case 0:
        value = time;
        color = "#C0C0C0";
        break;
      case 1:
        value = time;
        datalabel = radioName ? `${radioName}` : undefined;
        radio = radioName ?? "";
        color = "#00FF00";
        lable = radioName ? true : false;
        break;
      case 2:
        value = time;
        color = "#FFA500";
        break;
      default:
        value = "Unknown";
        color = "#000000";
    }
    const status = {
      // x: metricsData.ue_connection_state[i][0],
      name: value,
      description: metricsData.ue_connection_state[i][1],
      time,
      category,
      radio,
      color,
      // className: 'no-stroke-width stroke-red',
      dataLabels: {
        enabled: lable,
        useHTML: true,
        format: `<div style="display:flex;"><img width="12" height="12" src="${
          asset.celltower
        }" />&nbsp;<span style="width=auto;white-space: pre;">${datalabel ?? ""}</span></div>`,
      },
    };
    data.push(status);
    // if (lable) { labelCount++ }
  }
  return data;
}

// ------------------- New Code ----------------------------

/**
 * @deprecated Generates random data for the graph
 * @param time in hours
 * @param resolution in seconds
 */
export function generateConnectionTimetimeData(time = 6, resolution = 60) {
  // get the current Timestamp
  const start = moment().subtract(time, "hours").unix();
  const end = moment().unix(); // timestamp in seconds

  const events = ["Disconnected", "Connected", "Idle"];
  const eventObjs = ["Event 1", "Event 2", "Event 3", "Event 4", "Event 5", "Event 6"];

  const data: [number, number][] = [];

  const eventData: [number, string][] = [];

  for (let i = start; i < end; i += 60) {
    // generate random 0 ,1 ,2
    const value = Math.floor(Math.random() * 3);

    // get random event object
    const eventObj = eventObjs[Math.floor(Math.random() * eventObjs.length)];

    data.push([i * 1000, value]);

    const randomTime = Math.floor(Math.random() * 10) + 1;

    if (value === 1) {
      if (randomTime === 1) {
        eventData.push([i * 1000, `${events[value]} - ${eventObj}`]);
      }
    }
  }

  return {
    data,
    eventData,
  };
}

export type TimeLine = {
  [key: number]: {
    x: number;
    x2: number;
    y: number;
    name?: string;
  }[];
};
/**
 * Formats the data for the graph
 */
export function formatXrangeData(
  data: [number, number][],
  eventData: { [key: number]: string } = {},
  radioMap: { [key: string]: string } = {}
) {
  // If data is empty, return an empty object
  if (!data || !data.length) return {};

  // Calculate the resolution
  const resolution = (data[1][0] - data[0][0] || 1) * 1000;

  // Initialize previous radio ID
  let prevRadioID: string | null = null;

  // Use reduce to iterate over the data and build the result
  const result = data.reduce((acc, [time, value], i) => {
    const timestamp = time * 1000;

    // If the current value does not exist in the accumulator, initialize it
    if (!acc[value]) {
      acc[value] = [];
    }

    // Get the last entry for the current value
    const last = acc[value][acc[value].length - 1];

    // If the last entry exists and its end time is equal to the current timestamp minus the resolution
    // and the previous radio ID is equal to the event data for the current time,
    // then extend the end time of the last entry
    if (last && last.x2 === timestamp - resolution && prevRadioID === eventData[time]) {
      last.x2 = timestamp;
    } else {
      // Otherwise, add a new entry to the accumulator for the current value
      acc[value].push({
        x: timestamp, // start time
        x2: timestamp, // end time
        y: 0,
        name: radioMap[eventData[time]] ?? eventData[time] ?? "",
      });
    }

    // Update the previous radio ID
    prevRadioID = eventData[time];

    // Return the accumulator
    return acc;
  }, {} as TimeLine);

  // Return the result
  return result;
}

export type TimeLineEvent = {
  x: number;
  y: number;
  name: string;
  custom: {
    /** 0 - Disconnected 1 - Connected 2 - Idle */
    eventType: number;
    radioID: string;
    radioName: string;
    hide: boolean;
  };
};
export function formatEventData(data: [number, number, string][], radioMap: { [key: string]: string }) {
  // If data is empty, return an empty array
  if (!data || !data.length) return [];

  // Filter out the connected events
  const connectedEvents = data.filter((d) => d[1] === 1 && d[2]);

  // If there are no connected events, return an empty array
  if (!connectedEvents.length) return [];

  // Calculate the window width and the distance between events
  const windowWidth = typeof window !== "undefined" ? window.innerWidth - 100 : 1920 - 100;
  const distance = ((connectedEvents[connectedEvents.length - 1][0] - connectedEvents[0][0]) * 200) / windowWidth;
  
  // Initialize the result array and the previous timestamps for each level
  const result = [];
  const prevTimestamps = { L1: -1, L2: -1, L3: -1, L4: -1, L5: -1 };
  // Define the Y values for each level
  const levelYvalues = {
    L1: 10,
    L2: 8.5,
    L3: 7,
    L4: 5.5,
    L5: 4,
  };

  // Iterate over the connected events
  for (let i = 0; i < connectedEvents.length; i++) {
    const timestamp = connectedEvents[i][0] as number;
    const radioID = connectedEvents[i][2] as string;

    let valueY = 10;
    const radioName = radioMap[radioID] ?? "Unknown";

    const lastArr = Object.entries(prevTimestamps);
    // Iterate over the previous timestamps for each level
    for (let k = 0; k < lastArr.length; k++) {
      const level = lastArr[k][0] as keyof typeof prevTimestamps;
      const lastTimestamp = lastArr[k][1] as number;

      // If the previous timestamp for this level is -1 or the difference between the current timestamp and the previous timestamp is greater than or equal to the distance,
      // then update the y-value and the previous timestamp for this level and break the loop
      if (lastTimestamp === -1 || timestamp - lastTimestamp >= distance) {
        valueY = levelYvalues[level as keyof typeof levelYvalues];
        prevTimestamps[level as keyof typeof prevTimestamps] = timestamp;
        break;
      }
    }

    // Create a new event and add it to the result array
    const evt: TimeLineEvent = {
      x: timestamp * 1000,
      y: radioID ? valueY : 0,
      name: radioName,
      custom: {
        eventType: data[i][1],
        radioID,
        radioName,
        hide: false,
      },
    };
    result.push(evt);
  }
  // console.log('evtDiff', evtDiff)
  return result;
}

export type TimelineLog = {
  time: number;
  status: number;
  radio: string;
};
export function formatLogs(data: [number, number, string][], radioMap: { [key: string]: string }) {
  if (!data || !data.length) return [];
  const res: TimelineLog[] = [];

  for (let i = 0; i < data.length; i++) {
    const timestamp = data[i][0] as number;
    const value = data[i][1] as number;
    const radioID = data[i][2] as string;
    const radioName = radioMap[radioID] ?? "";

    const _data = {
      time: timestamp * 1000,
      status: value,
      radio: radioName,
    };
    res.push(_data);
  }

  return res;
}

export function syncMetrics(array: Array<Array<any>>, dependency: Record<number, string>) {
  let prevDepValue = null;
  let prevValue = null;

  const result = [];
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    const timestamp = item[0] as number;
    const value = item[1] as number;
    const dep = dependency[timestamp];
    if (dep !== prevDepValue || value !== prevValue) {
      result.push([timestamp, value, dep]);
    }
    prevDepValue = dep;
    prevValue = value;
  }
  return result as Array<[number, number, string]>;
}

/** @deprecated  Only for development only */
export function generateDeviceEventData(time = 6) {
  const events = [
    "Attach Success",
    "Attach Fail",
    "Detach Success",
    "Detach Fail",
    "Handover Success",
    "Handover Fail",
  ];

  const endTime = moment().valueOf();
  const startTime = moment().subtract(time, "hours").valueOf();

  const resolution = 60 * 1000; // 60 sec

  const data: EventData[] = [];

  for (let i = startTime; i < endTime; i += resolution) {
    const randomTime = Math.floor(Math.random() * 10) + 1;

    if (randomTime === 1) {
      const event = events[Math.floor(Math.random() * events.length)];
      data.push({
        creationTime: i,
        description: event,
        type: event,
        sourceObjectId: "",
        sourceObjectName: "",
        sourceSubName: "MME",
      });
    }
  }

  return data;
}

const COLOR = {
  GREEN: "#026951",
  RED: "#9B0505",
  ORANGE: "#DB5E04",
  GREY: "#A4A4A4",
};
export type EventGraphData = {
  x: number;
  y: number;
  name: string;
  color: string;
  filter : string;
  raw ?: EventData;
};

export function formatDeviceEventData(data: EventData[]) {
  if (!data || !data.length) return [];

  const res = data.map((item) => {
    const code = eventMap[item.type as keyof typeof eventMap]?.Code ?? "";
    const color = eventMap[item.type as keyof typeof eventMap]?.Color ?? "GREY";
    const filter = eventMap[item.type as keyof typeof eventMap]?.Filter;

    const iconjsx = eventDrop(COLOR[color as keyof typeof COLOR], code);
    const imgURL = svgJSXtoURL(iconjsx);

    return {
      x: item.creationTime,
      y: 4,
      name: item.type,
      color: color,
      filter : filter,
      marker: {
        symbol: `url(${imgURL})`,
        width: 27,
        height: 43,
      },
      raw : item
    } as EventGraphData;
  });
  return res;
}
